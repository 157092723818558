import { Invoice, UploadInvoiceForm } from '@types';
import {
  Modal,
  Form,
  Typography,
  DatePicker,
  Upload,
  Button,
  Row,
  notification,
  Input,
} from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { RcFile } from 'antd/es/upload';
import { UploadProps } from 'antd/lib/upload/interface';
import { useBackend } from 'integrations';
import moment from 'moment';
import { Props } from './UploadInvoiceModal.type';

const UploadInvoiceModal: React.FC<Props> = ({
  visible, onClose, order,
}) => {
  const maxInvoiceFiles = 1;
  const backend = useBackend();
  const [form] = Form.useForm<UploadInvoiceForm>();
  const { Dragger } = Upload;
  const [xmlFile, setXmlFile] = useState<RcFile | undefined>(
    undefined,
  );
  const [invoiceFiles, setInvoiceFiles] = useState<RcFile[]>(
    [],
  );
  const invoiceFileTypes = '.png, .jpg, .pdf, .jpeg';

  const [isLoading, setLoading] = useState<boolean>(false);

  const xmlFileProps: UploadProps = {
    name: 'xml',
    onRemove: () => {
      setXmlFile(undefined);
    },
    beforeUpload(file) {
      setXmlFile(file);
      return false;
    },
    fileList: xmlFile ? [xmlFile] : [],
    accept: '.xml',
  };

  const invoiceFileProps: UploadProps = {
    name: 'invoice',
    multiple: true,
    maxCount: 1,
    onRemove: (file: any) => {
      if (!invoiceFiles) {
        return;
      }
      const index = invoiceFiles.indexOf(file);
      const newFileList = invoiceFiles.slice();
      newFileList.splice(index, 1);
      setInvoiceFiles(newFileList);
    },
    beforeUpload(file, fileList) {
      setInvoiceFiles([...invoiceFiles, ...fileList].slice(0, maxInvoiceFiles));
      return false;
    },
    fileList: invoiceFiles || [],
    accept: invoiceFileTypes,
  };

  const onFinishFailed = (code?: string): void => {
    let description = '';
    switch (code) {
      case 'INVALID_RFC':
        description = 'El RFC de la factura no'
          + ' coincide con el RFC del cliente.';
        break;
      case 'CLIENT_404':
        description = 'No se encontraron datos del cliente.';
        break;
      case 'DUPLICATED_FOLIO':
        description = 'El folio de la factura esta duplicado';
        break;
      case 'INVALID_AMOUNT':
        description = 'El total de la factura es superior al total de la orden';
        break;
      case 'INVALID_USE':
        description = 'El uso del CFDI no es correcto';
        break;
      case 'INVALID_WAY':
        description = 'La forma de pago de la factura no es la correcta';
        break;
      case 'INVALID_METHOD':
        description = 'El metodo de pago de la factura es incorrecto';
        break;
      case 'EXCESSIVE_DAYS':
        description = 'La factura tiene una diferencia de fecha superior a los 15 dias con respecto a hoy';
        break;
      case 'DAY_403':
        description = 'Hoy no se permite subir facturas, solo se permite viernes, sabado, domingo y lunes';
        break;
      default:
        description = 'Verifique si su archivo XML es valido';
        break;
    }
    notification.error({
      message: '¡Ocurrio un error al guardar la factura!',
      description,
    });
  };

  const onSubmit = async (data: any): Promise<void> => {
    setLoading(true);
    if (!xmlFile) {
      setLoading(false);
      notification.warning(
        { message: 'Necesitas seleccionar un archivo .xml' },
      );
      return;
    }

    const formData = new FormData();
    formData.append('xml_file', xmlFile);
    if (invoiceFiles.length === 0) {
      setLoading(false);
      notification.warning(
        {
          message: 'Necesitas seleccionar por lo menos un archivo',
        },
      );
      return;
    }
    if (invoiceFiles[0].size > 2000000) {
      setLoading(false);
      notification.warning(
        {
          message: 'La factura pesa mas de 2MB, por favor selecciona un archivo mas liviano',
        },
      );
      return;
    }
    formData.append('invoice_file', invoiceFiles[0]);
    if (invoiceFiles.length > 1) {
      formData.append('extra_file', invoiceFiles[1]);
    }

    if (data.reinvoice_folio) {
      formData.append('reinvoice_folio', data.reinvoice_folio);
    }

    formData.append(
      'delivery_date',
      moment(data.delivery_date).format('YYYY-MM-DD'),
    );
    formData.append('order', `${order?.id}`);

    const [response1, error1] = await backend.invoice.post(
      '/isLess',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
    if (error1) {
      onFinishFailed(error1.response?.data.code);
      setLoading(false);
      return;
    }
    if (response1) {
      setLoading(false);
      if ((response1.data as any).message && !window.confirm('La factura tiene un monto menor al del pedido, continuar?')) {
        setLoading(false);
        return;
      }
      const [response, error] = await backend.invoice.post(
        '/create',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );

      if (error) {
        onFinishFailed(error.response?.data.code);
        setLoading(false);
        return;
      }
      if (response) {
        notification.success({
          message: (
            'Se ha guardado la factura exitosamente'
          ),
        });
        const invoice: Invoice = response.data as Invoice;
        form.resetFields();
        setLoading(false);
        onClose(true, invoice);
      }
    }
  };

  const validateForm = async (): Promise<void> => {
    try {
      if (invoiceFiles.length < maxInvoiceFiles) {
        alert(`Debes subir archivo de la factura en los formatos ${invoiceFileTypes} ademas del XML`);
        return;
      }
      const values = await form.validateFields();
      await onSubmit(values);
    } catch (info) {
      notification.error({
        message: 'Error al guardar factura.',
      });
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => onClose(false)}
      okText="Guardar"
      onOk={validateForm}
      okButtonProps={{ disabled: invoiceFiles.length === 0 }}
      title={`Facturación Pedido No. ${order?.id}`}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
      >
        <Form.Item>
          <Typography>
            Subir archivo XML de Factura
          </Typography>
          <Row justify="center">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Upload {...xmlFileProps}>
              <Button icon={<UploadOutlined />}>Cargar XML</Button>
            </Upload>
          </Row>
        </Form.Item>
        <Form.Item
          name="delivery_date"
          label="Fecha Entrega Producto"
          required
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="reinvoice_folio"
          label="Folio de refactura (solo para casos de refactura)"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Typography>
            {`Subir factura de maximo 2MB en alguno de los formatos ${invoiceFileTypes} (Max. ${maxInvoiceFiles}) 
            (Restante ${maxInvoiceFiles - invoiceFiles.length})`}
          </Typography>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Dragger {...invoiceFileProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Haz click o arrastra el archivo a subir
            </p>
            <p className="ant-upload-hint">
              Sólo se aceptan archivos PNG, JPG y PDF.
            </p>
          </Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UploadInvoiceModal;
