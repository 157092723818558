import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { Content } from 'antd/lib/layout/layout';
import {
  Button,
  Col,
  notification, Row, Typography,
} from 'antd';
import './style.css';
import { useHistory, useParams } from 'react-router';
import Title from 'components/Title';
import { useBackend } from 'integrations';
import {
  Laboratory,
  Order, Product,
} from '@types';
import { Table } from 'components/Table/Table.styled';
import Table1 from 'components/Table';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import RequisitionStatusTag from 'components/RequisitionStatusTag';
import OrderSummary from 'components/OrderSummary';
import useAuth from 'hooks/useAuth';
import { OrderStatus } from 'constants/strings';
import InvoiceTable from 'components/InvoiceTable';
import NumberFormat from 'react-number-format';
import OrderInvoiceStatusTag from 'components/OrderInvoiceStatusTag';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { DownloadOutlined } from '@ant-design/icons';
import OrderSummaryPrint from 'components/OrderSummary/OrderSummaryPrint';

const OrderDetail: React.VC = (
  { verboseName, parentName },
) => {
  const backend = useBackend();
  const history = useHistory();
  const { id: orderId } = useParams<{ id: string; }>();
  const [isLoading, setLoading] = useState(true);
  const [order, setOrders] = useState<Order | undefined>(undefined);
  let subtotal = 0;
  let iva = 0;
  let ieps = 0;
  let total = 0;
  let flag = 1;

  const { isProvider, isAdmin } = useAuth();

  const shouldShowModifyOrder = isAdmin || isProvider;
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const renderData = (): any => (
    order?.requisitions.map((requisition, index) => ({
      no: index + 1,
      key: (requisition.product as Product).key,
      active_substance: (requisition.product as Product).active_substance,
      presentation: (requisition.product as Product).presentation,
      laboratory: ((requisition.product as Product).laboratory as Laboratory)?.name,
      iva: (requisition.product as Product).iva,
      ieps: (requisition.product as Product).ieps,
      id: requisition.id,
      order: order.id.toString(),
      product: (requisition.product as Product).name,
      quantity_requested: requisition.quantity_requested,
      quantity_accepted: requisition.quantity_accepted,
      price: `$ ${requisition.price.toFixed(2)}`,
      amount: `$ ${(requisition.price * requisition.quantity_accepted).toFixed(2)}`,
      status: requisition.status,
    }))
  );

  const calcSummary = (): any => {
    if (flag) {
      const data = order?.requisitions.map((requisition, index) => ({
        no: index + 1,
        key: (requisition.product as Product).key,
        active_substance: (requisition.product as Product).active_substance,
        presentation: (requisition.product as Product).presentation,
        laboratory: ((requisition.product as Product).laboratory as Laboratory)?.name,
        iva: (requisition.product as Product).iva,
        ieps: (requisition.product as Product).ieps,
        id: requisition.id,
        order: order.id.toString(),
        product: (requisition.product as Product).name,
        quantity_requested: requisition.quantity_requested,
        quantity_accepted: requisition.quantity_accepted,
        price: requisition.price,
        amount: (requisition.price * requisition.quantity_accepted),
        status: requisition.status,
      }));
      data?.forEach((element) => {
        subtotal += element.amount;
        iva += (element.amount * (element.iva / 100));
        ieps += (element.amount * (element.ieps / 100));
      });
      total = subtotal + iva + ieps;
      flag = 0;
    }
  };

  const drawSummary = (): any => (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} />
        <Table.Summary.Cell index={4} />
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} />
        <Table.Summary.Cell index={7} />
        <Table.Summary.Cell index={8} />
        <Table.Summary.Cell index={9} />
        <Table.Summary.Cell index={10}>Subtotal</Table.Summary.Cell>
        <Table.Summary.Cell index={11} />
        <Table.Summary.Cell index={12} align="right">
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={subtotal}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} />
        <Table.Summary.Cell index={4} />
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} />
        <Table.Summary.Cell index={7} />
        <Table.Summary.Cell index={8} />
        <Table.Summary.Cell index={9} />
        <Table.Summary.Cell index={10}>IVA</Table.Summary.Cell>
        <Table.Summary.Cell index={11} />
        <Table.Summary.Cell index={12} align="right">
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={iva}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} />
        <Table.Summary.Cell index={4} />
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} />
        <Table.Summary.Cell index={7} />
        <Table.Summary.Cell index={8} />
        <Table.Summary.Cell index={9} />
        <Table.Summary.Cell index={10}>IEPS</Table.Summary.Cell>
        <Table.Summary.Cell index={11} />
        <Table.Summary.Cell index={12} align="right">
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={ieps}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} />
        <Table.Summary.Cell index={4} />
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} />
        <Table.Summary.Cell index={7} />
        <Table.Summary.Cell index={8} />
        <Table.Summary.Cell index={9} />
        <Table.Summary.Cell index={10}>Total</Table.Summary.Cell>
        <Table.Summary.Cell index={11} />
        <Table.Summary.Cell index={12} align="right">
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={total}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );

  const renderSummary = (): any => {
    calcSummary();
    return drawSummary();
  };

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    const [result, error] = await backend.orders.getOne(orderId);

    if (error || !result) {
      notification.error({
        message: 'Ocurrió un error al cargar los pedidos!',
        description: 'Intentalo más tarde',
      });
      setLoading(false);
      return;
    }
    setOrders(result.data);
    setLoading(false);
  }, [backend.orders, orderId]);

  useEffect(() => {
    fetchOrders();
  }, [history, fetchOrders]);

  const columns = [
    {
      title: '#',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Clave',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Sustancia Activa',
      dataIndex: 'active_substance',
      key: 'active_substance',
    },
    {
      title: 'Presentacion',
      dataIndex: 'presentation',
      key: 'presentation',
    },
    {
      title: 'Laboratorio',
      dataIndex: 'laboratory',
      key: 'laboratory',
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (price: number) => (
        <Row>
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={price}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Row>
      ),
    },
    {
      title: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      render: (iva1: number) => (
        <Row>
          {`${Math.floor(iva1)}%`}
        </Row>
      ),
    },
    {
      title: 'IEPS',
      dataIndex: 'ieps',
      key: 'ieps',
      render: (ieps1: number) => (
        <Row>
          {`${Math.floor(ieps1)}%`}
        </Row>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <RequisitionStatusTag status={status} />
      ),
    },
    {
      title: 'Cantidad Solicitada',
      dataIndex: 'quantity_requested',
      key: 'quantity_requested',
      render: (c1: number) => (
        <Row>
          {c1}
        </Row>
      ),
    },
    {
      title: 'Cantidad Aceptada',
      dataIndex: 'quantity_accepted',
      key: 'quantity_accepted',
      render: (c2: number) => (
        <Row>
          {c2}
        </Row>
      ),
    },
    {
      title: 'Importe',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => (
        <Row>
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={amount}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Row>
      ),
    },
  ];

  if (isLoading || !order) {
    return <LoadingIndicator />;
  }

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div className="noPrint" ref={componentRef}>
      <style type="text/css" media="print">
        {'\
      @page { size: landscape; }\
      '}
      </style>
      <OrderSummaryPrint order={order} />
      <Table1
        rowKey={(row) => row.id}
        data={renderData()}
        actions={[]}
        columns={columns}
        summary={renderSummary}
      />
      <Row style={{
        position: 'fixed', bottom: 0, left: '45%', backgroundColor: 'white', fontSize: '8px',
      }}
      >
        {'Fecha de impresion: '}
        {new Date().toLocaleString()}
      </Row>
    </div>
  ));

  return (
    <Content>
      <Title viewName={verboseName} parentName={parentName} />
      <ComponentToPrint />
      <OrderSummary order={order} />
      <Table1
        rowKey={(row) => row.id}
        data={renderData()}
        actions={[
          {
            text: 'Imprimir',
            action: () => handlePrint(),
          },
          {
            text: 'Modificar',
            action: () => history.push(
              `/pedidos/${orderId}/modificar`,
            ),
            hidden: !shouldShowModifyOrder,
            disabled: order.status !== OrderStatus.PENDING,
            tooltip: (order.status !== OrderStatus.PENDING)
              ? 'Este pedido ya fue modificado' : 'Modificar pedido',
          },
        ]}
        columns={columns}
        summary={renderSummary}
      />
      <Row justify="center">

        <Typography.Title
          level={3}
          style={{ marginTop: '3em' }}
        >
          Facturas
        </Typography.Title>
      </Row>
      <Row justify="center">
        <Typography.Text>
          Estado total de la facturacion del pedido:
        </Typography.Text>
      </Row>
      <Row justify="center">
        <OrderInvoiceStatusTag status={order.invoice_status} />
      </Row>
      <InvoiceTable
        invoices={order.invoices}
        onRefresh={() => fetchOrders()}
      />
    </Content>
  );
};

export default OrderDetail;
