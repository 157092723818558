import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { AddresseeTypes, Announcement } from '@types';
import {
  Button, notification, Popconfirm, Tooltip, Typography,
} from 'antd';
import { useHistory } from 'react-router';
import Table, { Column } from 'components/Table';
import React from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import moment from 'moment';
import { useBackend } from 'integrations';

interface Props {
  data: Announcement[];
  loading: boolean;
}

const AnnouncementsList: React.FC<Props> = ({ data, loading }) => {
  const history = useHistory();
  const backend = useBackend();
  const deleteAnnouncement = async (
    id: number,
  ): Promise<void> => {
    const [result, error] = await backend.announcements.delete(
      `delete/${id}`,
      {
      },
    );
    if (error || !result) {
      notification.error({
        message: 'Ocurrió un error al borrar!',
        description: error?.response?.data.code === 'notallowed' ? 'Este objeto tiene otros objetos relacionados' : 'Intentalo más tarde',
      });
      return;
    }
    notification.success({
      message: 'Se ha eliminado el objeto exitosamente',
    });
    history.push('/circulares');
  };

  const CONTENT_LIMIT = 70;
  const columns: Column[] = [
    {
      title: 'Fecha de creación',
      dataIndex: 'created_at',
      key: 'created_at',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => moment(
        a.created_at,
      ).unix() - moment(
        b.created_at,
      ).unix(),
      render: (_: number, announ: Announcement) => (
        moment(announ.created_at).format('YYYY-MM-DD hh:mm')
      ),
    },
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
    },
    {
      title: 'Contenido',
      dataIndex: 'content',
      key: 'content',
      sorter: (a: any, b: any) => a.content.localeCompare(b.content),
      render: (_: number, announ: Announcement) => (
        `${announ.content.substr(0, CONTENT_LIMIT)}`
        + `${announ.content.length > CONTENT_LIMIT ? '...' : ''}`
      ),
    },
    {
      title: 'Dirigido a',
      dataIndex: 'addressee',
      key: 'addressee',
      sorter: (a: any, b: any) => a.addressee.localeCompare(b.addressee),
      render: (_: number, announ: Announcement) => (
        AddresseeTypes.find((obj) => (obj.key === announ.addressee))?.value
      ),
    },
    {
      title: 'Inicio de vigencia',
      dataIndex: 'validity_start',
      key: 'validity_start',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => moment(
        a.validity_start,
      ).unix() - moment(
        b.validity_end,
      ).unix(),
      render: (_: number, announ: Announcement) => (
        moment(announ.validity_start).format('YYYY-MM-DD hh:mm')
      ),
    },
    {
      title: 'Fin de vigencia',
      dataIndex: 'validity_end',
      key: 'validity_end',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => moment(
        a.validity_end,
      ).unix() - moment(
        b.validity_end,
      ).unix(),
      render: (_: number, announ: Announcement) => (
        moment(announ.validity_end).format('YYYY-MM-DD hh:mm')
      ),
    },
    {
      title: 'Enlace a archivo',
      dataIndex: 'file_url',
      key: 'file_url',
      render: (_: number, announ: Announcement) => (
        <Typography.Link
          href={announ.file_url}
        >
          Archivo adjunto
        </Typography.Link>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: number, announ: Announcement) => (
        <Tooltip title="Ver detalles - Eliminar">
          <Button
            shape="circle"
            icon={<SearchOutlined />}
            onClick={() => history.push(`circulares/${announ.id}`)}
          />
          <Popconfirm
            title={`¿Está seguro de eliminar: ${announ.title}?`}
            onConfirm={() => deleteAnnouncement(announ.id)}
          >
            <Button shape="circle" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];

  if (loading) return <LoadingIndicator />;
  return (
    <Table columns={columns} data={data} rowKey={(record) => record.id} />
  );
};

export default AnnouncementsList;
