import React, {
  useState, useCallback, useEffect,
} from 'react';
import { Content } from 'antd/lib/layout/layout';
import {
  notification,
  Form,
} from 'antd';
import { useHistory } from 'react-router';
import Title from 'components/Title';
import { useBackend } from 'integrations';
import {
  ChangePriceForm,
  Product,
  ProductGroup,
} from '@types';
import PriceChangeForm from 'components/PriceChangeForm';
import { MICROSERVICE_ROUTE, MICROSERVICE_ENABLED } from 'settings';
import useAuth from 'hooks/useAuth';

const PriceChange: React.VC = ({ verboseName, parentName }) => {
  const [form] = Form.useForm();
  const backend = useBackend();
  const history = useHistory();
  const auth = useAuth();

  const [isLoading, setLoading] = useState(false);
  const [products, setProducts] = useState<Product[] | undefined>(undefined);

  const fetchProducts = useCallback(async () => {
    setLoading(true);

    if (MICROSERVICE_ENABLED) {
      let temp: ProductGroup[] = [];
      await fetch(MICROSERVICE_ROUTE, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, // this line is important, if this content-type is not set it wont work
        body: `c=p&a=${auth.user?.id}&b=${auth.user?.groups[0]}`,
      }).then((response) => response.json()).then((response) => {
        temp = response;
      });
      const prodParse = temp.map((product) => ({
        ...product,
        provider: {
          ...product.providers[0],
          laboratory: product.laboratory?.name,
        },
      }));
      setProducts(prodParse);
    } else {
      const [result, error] = await backend.products.getAll<ProductGroup[]>(
        'status=Aceptado',
      );
      if (error || !result) {
        notification.error({
          message: 'Ocurrió un error al cargar los producto!',
          description: 'Intentalo más tarde',
        });
        setLoading(false);
        return;
      }
      const parsed = result.data.map((product) => ({
        ...product,
        provider: {
          ...product.providers[0],
          laboratory: product.laboratory?.name,
        },
      }));
      setProducts(parsed);
    }
    setLoading(false);
  }, [backend.products]);

  useEffect(() => {
    fetchProducts();
  }, [history, fetchProducts]);

  const onFinishFailed = (code: string): void => {
    switch (code) {
      case 'INVALID_TOKEN':
        notification.error({
          message: 'Token inválido.',
          description: 'Verifique que el código fue escrito '
            + 'correctamente o que aún es válido.',
        });
        form.setFields([{
          name: ['token'],
          errors: ['El token ingresado es inválido.'],
        }]);
        form.scrollToField(['token']);
        break;
      default:
        notification.error({
          message: '¡Ocurrió un error al intentar guardar!',
          description: 'Inténtalo más tarde.',
        });
        break;
    }
  };

  const onFinish = async (values: ChangePriceForm): Promise<void> => {
    setLoading(true);
    const productsWrapper = MICROSERVICE_ENABLED ? Object.values(values.products) : values.products;
    const payload: ChangePriceForm = {
      ...values,
      products: productsWrapper.filter((product) => product !== undefined),
    };
    const [res, error] = await backend.products.post('price_change', {
      ...payload,
    });

    if (error) {
      onFinishFailed(error.response?.data.code);
    } else {
      const tokenValid = ((res?.data as any).token === 'true');
      if (tokenValid) {
        notification.success({
          message: 'Cambio de precio aplicado exitosamente',
          description: 'Los precios de sus productos se han '
            + 'actualizado con los datos ingresados.',
        });
      } else {
        notification.success({
          message: 'Cambio de precio guardado, a la espera del codigo',
          description: 'Los precios de sus productos se han '
            + 'guardado, pero se aplicaran una vez ingrese el codigo',
        });
      }
      fetchProducts();
    }
    setLoading(false);
  };

  return (
    <Content>
      <Title viewName={verboseName} parentName={parentName} />
      <PriceChangeForm
        form={form}
        products={products}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        isLoading={isLoading}
      />
    </Content>
  );
};

export default PriceChange;
