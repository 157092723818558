import React, { useEffect, useRef } from 'react';
import {
  Form, Input, Select,
} from 'antd';
import Button from 'react-bootstrap/Button';
import { FilterContainer } from './TableFilter.styled';

export interface FormType<T> {
  field: string;
  value: string;
}

export interface Props<T> {
  onFilter: (filtered: T[]) => void;
  fieldsToFilter: { key: keyof T; value: string; }[];
  customFieldsToFilter?: { key: string; value: string; }[];
  data: T[];
  filterAny: (data: T[], value: string) => T[];
  useAny?: boolean;
}

const TableFilter = <T extends { [x: string]: any }>({
  onFilter,
  fieldsToFilter,
  customFieldsToFilter,
  data,
  filterAny,
  useAny = false,
}: Props<T>): React.ReactElement<Props<T>> => {
  const searchInputRef = useRef<any>(null);
  const [form] = Form.useForm();
  const searchArray = (element: any, levels: string[], value: string): boolean => {
    if (typeof element[levels[0]] === 'string') {
      return element[levels[0]].toLowerCase().includes(
        value.toLowerCase(),
      );
    }
    if (!Array.isArray(element[levels[0]])) {
      const levels1 = [...levels];
      levels1.shift();
      return searchArray(element[levels[0]], levels1, value);
    }
    if (Array.isArray(element[levels[0]])) {
      let res = false;
      (element[levels[0]]).forEach((element1: any) => {
        const levels1 = [...levels];
        levels1.shift();
        res = searchArray(element1, levels1, value);
      });
      return res;
    }
    return false;
  };

  const filter = (values: FormType<T>): void => {
    let newData;

    if (values.field === 'any') {
      newData = filterAny(data, values.value);
    } else if (values.field.includes('.')) {
      newData = data.filter((element) => {
        const levels = values.field.split('.');
        const res = searchArray(element, levels, values.value);
        return res;
      });
    } else {
      newData = data.filter(
        (element) => {
          if (element[values.field]) {
            switch (typeof element[values.field]) {
              case 'string':
                return element[values.field].toLowerCase().includes(
                  values.value.toLowerCase(),
                );
              case 'number':
                return values.value === '' ? true : Number(element[values.field]) === Number(values.value as unknown);
              default:
                break;
            }
          }
          return false;
        },
      );
    }
    onFilter(newData);
  };

  useEffect(() => {
    const callback = (e: KeyboardEvent): void => {
      const isSearch = (event: KeyboardEvent): boolean => (
        event.keyCode === 114
        || (event.ctrlKey && event.key === 'f')
        || (event.metaKey && event.key === 'f')
      );

      if (isSearch(e)) {
        e.preventDefault();
        searchInputRef.current?.focus({
          cursor: 'all',
        });
      }
    };

    window.addEventListener('keydown', callback);

    // remove the event when component unmount
    return () => window.removeEventListener('keydown', callback);
  }, []);

  return (
    <FilterContainer>
      <Form
        form={form}
        layout="vertical"
        name="filter"
        onFinish={(values) => filter({ ...values.filter })}
        autoComplete="off"
        initialValues={useAny ? {
          filter: {
            field: 'any',
          },
        } : {}}
      >
        <Form.Item label="Filtrar por:">
          <Input.Group compact>
            <Form.Item name={['filter', 'field']} noStyle>
              <Select style={{ width: '30%' }}>
                {useAny && (
                  <Select.Option
                    key="any-field"
                    value="any"
                  >
                    Cualquier campo
                  </Select.Option>
                )}
                {customFieldsToFilter?.map((field) => (
                  <Select.Option
                    key={`${field.key as string}`}
                    value={`${field.key as string}`}
                  >
                    {field.value}
                  </Select.Option>
                ))}
                {fieldsToFilter.map((field) => (
                  <Select.Option
                    key={`${field.key as string}`}
                    value={`${field.key as string}`}
                  >
                    {field.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={['filter', 'value']} noStyle>
              <Input
                allowClear
                ref={searchInputRef}
                style={{ width: '45%' }}
                onChange={() => filter({ ...form.getFieldValue('filter') })}
              />
            </Form.Item>
            <Button variant="primary" size="sm">
              Buscar
            </Button>
          </Input.Group>
        </Form.Item>
      </Form>
    </FilterContainer>
  );
};

export default TableFilter;
