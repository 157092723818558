import { Table as TableAntd } from 'antd';
import styled from 'styled-components';

export const Table = styled(TableAntd)`

  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 2px 8px;
  }
  .ant-table-cell .ant-row button {
    margin: 0.25em 0px 0.25em 0px;
  }
  .ant-table-tbody > tr > td{
    color: black;
  }
  .ant-row{
    display: block;
    text-align: right;
  }
  .ant-table-cell-row-hover{
    color:black;
  }
  .ant-table-tbody > tr:nth-child(odd) > td{
    color: white;
    background:#003366;
  }
  .ant-table-tbody > tr:nth-child(odd) > td > div > span{
    color: white;
  }

  @media print {
    .ant-table-summary {
      display: table-row-group;
    }
}

`;
