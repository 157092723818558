// 2=Staging, 3=Produccion, 1=Localhost(Desarrollo)
const enviroment = Number(3);

let back: string;
let micEn: boolean;
let micRoute: string;
switch (enviroment) {
  case 2:
    back = 'https://api.staging.gaqsa.com';
    micEn = false;
    micRoute = 'https://staging.gaqsa.com/dd';
    break;

  case 3:
    back = 'https://api.gaqsa.com';
    micEn = true;
    micRoute = 'https://quick.api.gaqsa.com';
    break;

  default:
    back = 'http://localhost:8000';
    micEn = true;
    micRoute = 'http://localhost';
    break;
}
export const BACKEND_MAIN_EP = process.env.REACT_APP_BACKEND_ROUTE
  || back;
export const MICROSERVICE_ENABLED = micEn;
export const MICROSERVICE_ROUTE = micRoute;
export const PRODUCTS_ROOT = '/products';
export const USERS_ROOT = '/users';
export const PRODUCTS_OPTIONS_ROOT = '/options';
export const ORDERS_ROOT = '/orders';
export const REQUISITIONS_ROOT = `${ORDERS_ROOT}/requisitions`;
export const PROVIDERS_ROOT = '/providers';
export const CLIENTS_ROOT = '/clients';
export const OFFERS_ROOT = '/offers';
export const LABORATORY_ROOT = `${PRODUCTS_ROOT}/laboratory`;
export const CATEGORY_ROOT = `${PRODUCTS_ROOT}/category`;
export const INVOICE_ROOT = '/invoices';
export const ANNOUNCEMENT_ROOT = '/announcements';
