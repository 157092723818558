import React, { useCallback, useEffect, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import {
  Button,
  Descriptions,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Tooltip,
} from 'antd';
import { useHistory } from 'react-router';
import Title from 'components/Title';
import { useBackend } from 'integrations';
import {
  MinusOutlined,
} from '@ant-design/icons';
import useShoppingCart from 'hooks/shoppingCart';
import { ProductGroup } from '@types';
import LoadingIndicator from 'components/LoadingIndicator';
import { Table } from 'components/Table/Table.styled';
import Table1 from 'components/Table';
import FormButton from 'components/FormButton';
import NumberFormat from 'react-number-format';

export interface Props {
  product: ProductGroup;
}
interface RequisitionPreview {
  id: number;
  key?: string;
  quantity: number;
  total: number;
  subtotal: number;
  ieps_percentage: number;
  iva_percentage: number;
  iva_total: number;
  ieps_total: number;
  price: number;
  original_price?: number;
  name: string;
  provider: string;
  presentation: string;
  lab: string;
  category: string;
}

interface OrderPreview {
  total: number;
  subtotal: number;
  ieps_total: number;
  iva_total: number;
  products: RequisitionPreview[];
}

const CreateOrder: React.VC = ({ verboseName, parentName }) => {
  const backend = useBackend();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [preview, setPreview] = useState<OrderPreview | undefined>(undefined);
  const [comment, setComment] = useState('');

  const {
    productsCart, addProducts, removeProducts, clear,
  } = useShoppingCart();

  const onFinishFailed = (): void => {
    notification.error({
      message: '¡Ocurrió un error al intentar guardar!',
      description: 'Intentalo después.',
    });
  };

  const drawSummary = (): any => (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} />
        <Table.Summary.Cell index={4} />
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} />
        <Table.Summary.Cell index={7} />
        <Table.Summary.Cell index={8} />
        <Table.Summary.Cell index={9}>Subtotal</Table.Summary.Cell>
        <Table.Summary.Cell index={10} align="right">
          $
          {preview?.subtotal.toFixed(2)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={11} />
        <Table.Summary.Cell index={12} />
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} />
        <Table.Summary.Cell index={4} />
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} />
        <Table.Summary.Cell index={7} />
        <Table.Summary.Cell index={8} />
        <Table.Summary.Cell index={9}>IVA</Table.Summary.Cell>
        <Table.Summary.Cell index={10} align="right">
          $
          {preview?.iva_total.toFixed(2)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={11} />
        <Table.Summary.Cell index={12} />
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} />
        <Table.Summary.Cell index={4} />
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} />
        <Table.Summary.Cell index={7} />
        <Table.Summary.Cell index={8} />
        <Table.Summary.Cell index={9}>IEPS</Table.Summary.Cell>
        <Table.Summary.Cell index={10} align="right">
          $
          {preview?.ieps_total.toFixed(2)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={11} />
        <Table.Summary.Cell index={12} />
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} />
        <Table.Summary.Cell index={4} />
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} />
        <Table.Summary.Cell index={7} />
        <Table.Summary.Cell index={8} />
        <Table.Summary.Cell index={9}>Total</Table.Summary.Cell>
        <Table.Summary.Cell index={10} align="right">
          $
          {preview?.total.toFixed(2)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={11} />
        <Table.Summary.Cell index={12} />
      </Table.Summary.Row>
    </Table.Summary>
  );

  const renderSummary = (): any => (
    drawSummary()
  );

  const fetchPreview = useCallback(async () => {
    setLoading(true);
    const [result, error] = await backend.orders.post<OrderPreview, any>(
      '/preview',
      productsCart,
    );

    if (error || !result) {
      notification.error({
        message: 'Ocurrio un error al cargar el resumen del pedido.',
        description: 'Intentelo mas tarde',
      });
      setLoading(false);
      return;
    }
    setPreview(result.data);
    setLoading(false);
  }, [backend.orders, productsCart]);

  useEffect(() => {
    fetchPreview();
  }, [fetchPreview]);
  const onFinish = async (values: any): Promise<void> => {
    setLoading(true);
    const payload = { productsCart, comment: values.comment };
    const [, error] = await backend.orders.createOne(payload);

    if (error) {
      onFinishFailed();
      return;
    }
    clear();
    notification.success({
      message: '¡Petición de orden creado exitosamente!',
      description: 'Su orden de compra ha sido recibida y será procesada'
        + '. El proveedor le informará lo mas pronto posible '
        + 'el estatus de su solicitud.',
    });
    history.replace('/pedidos');

    setLoading(false);
  };

  const columns = [
    {
      title: 'Clave',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Proveedor',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'Presentación',
      dataIndex: 'presentation',
      key: 'presentation',
    },
    {
      title: 'Laboratorio',
      dataIndex: 'laboratory',
      key: 'laboratory',
    },
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (price: number) => (
        <Row>
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={price}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Row>
      ),
    },
    {
      title: 'IVA',
      dataIndex: 'iva_percentage',
      key: 'iva_percentage',
      render: (iva1: number) => (
        <Row>
          {iva1}
        </Row>
      ),
    },
    {
      title: 'IEPS',
      dataIndex: 'ieps_percentage',
      key: 'ieps_percentage',
      render: (iva1: number) => (
        <Row>
          {iva1}
        </Row>
      ),
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (id: number, product: RequisitionPreview) => (
        <Row>
          <InputNumber<number>
            defaultValue={product.quantity}
            min={1}
            step={1}
            onBlur={(event) => (
              product.quantity !== Number(event.target.value)
            ) && addProducts({
              id: product.id,
              quantity: Number(event.target.value),
            })}
          />
        </Row>
      ),
    },
    {
      title: 'SubTotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      render: (subtotal: number) => (
        <Row>
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={subtotal}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Row>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (total: number) => (
        <Row>
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={total}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Row>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      render: (id: number, product: RequisitionPreview) => (
        <>
          <Tooltip title="Eliminar del carrito">
            <Button
              shape="circle"
              icon={<MinusOutlined />}
              onClick={() => removeProducts({
                id: product.id,
                quantity: -1,
              })}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Content>
      <Title viewName={verboseName} parentName={parentName} />
      <Form
        name="productForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {isLoading || !productsCart || !preview ? <LoadingIndicator /> : (
          <>
            <Table1
              data={preview.products.map((product) => ({
                id: product.id,
                key: product.key,
                name: product.name,
                provider: product.provider,
                presentation: product.presentation,
                laboratory: product.lab,
                category: product.category,
                price: `$${product.price.toFixed(2)}`,
                iva: `$${product.iva_total.toFixed(2)}`,
                ieps: `$${product.ieps_total.toFixed(2)}`,
                quantity: product.quantity,
                original_price: `$${product.original_price?.toFixed(2)}`,
                total: `$${product.total.toFixed(2)}`,
                subtotal: `$${product.subtotal.toFixed(2)}`,
                iva_percentage: `${product.iva_percentage}%`,
                ieps_percentage: `${product.ieps_percentage}%`,
              }))}
              columns={columns}
              rowKey={(row) => `${row.id}`}
              summary={renderSummary}
              pagination={{ position: ['topCenter', 'bottomCenter'] }}
            />
            <Form.Item
              name="comment"
              label="Comentario del pedido"
            >
              <Input />
            </Form.Item>
            <FormButton
              text="Confirmar"
              disabled={
                !preview
                || productsCart.length === 0 || preview.products.length === 0
              }
            />
          </>
        )}

      </Form>
    </Content>
  );
};

export default CreateOrder;
