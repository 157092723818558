/* eslint-disable react-hooks/exhaustive-deps */

import { Maybe, Product, ProductGroup } from '@types';
import {
  Modal,
  notification,
  Form,
  Select,
  Typography,
  Row,
  message,
} from 'antd';
import LoadingIndicator from 'components/LoadingIndicator';
import useAuth from 'hooks/useAuth';
import { useBackend } from 'integrations';
import React, { useCallback, useEffect, useState } from 'react';
import { MICROSERVICE_ENABLED, MICROSERVICE_ROUTE } from 'settings';
import LaboratoryListLaboratory from 'views/Laboratory.ListLaboratory';
import { Props } from './GroupProductModal.type';

const { Option } = Select;

interface GroupProductForm {
  product: number;
}

const GroupProductModal: React.FC<Props> = ({
  visible, onClose, products,
}) => {
  const [form] = Form.useForm<GroupProductForm>();
  const auth = useAuth();

  const [loading, setLoading] = useState(false);
  const [newProd, setNewProd] = useState<Maybe<Product>>(undefined);
  const [
    existingProducts, setProducts,
  ] = useState<Product[] | undefined>(undefined);
  const backend = useBackend();

  const onFinishFailed = (code: string): void => {
    switch (code) {
      case 'DUPLICATED':
        notification.error({
          message: '¡Ocurrió un error al intentar guardar!',
          description: 'El producto objetivo tiene el mismo proveedor',
        });
        break;

      default:
        notification.error({
          message: '¡Ocurrió un error al intentar guardar!',
          description: 'Intentalo después.',
        });
        break;
    }
  };
  const fetchProducts = useCallback(async () => {
    setLoading(true);
    if (MICROSERVICE_ENABLED) {
      let temp: Product[] = [];
      await fetch(MICROSERVICE_ROUTE, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, // this line is important, if this content-type is not set it wont work
        body: `c=p&a=${auth.user?.id}&b=${auth.user?.groups[0]}`,
      }).then((response) => response.json()).then((response) => {
        temp = response;
      });
      temp = temp.filter((el) => el.status === 'Aceptado');
      setProducts(temp);
    } else {
      const [result, error] = await backend.products.getAll(
        'status=Aceptado',
      );

      if (error || !result) {
        notification.error({
          message: 'Ocurrió un error al cargar el producto!',
          description: 'Intentalo más tarde',
        });
        setLoading(false);
        return;
      }
      setProducts(result.data);
    }
    setLoading(false);
  }, [backend.products]);

  useEffect(() => {
    if (products === undefined) {
      fetchProducts();
    }
  }, [fetchProducts, products]);

  const handleUpdate = async (data: any): Promise<void> => {
    setLoading(true);
    const providers = products?.map(
      (product) => ({
        ...product.provider,
        laboratory: product.laboratory.id,
      }),
    );

    const payload = { ...data, providers };
    const [response, error] = await backend.products.post('group', payload);

    if (error || !response || !response.data) {
      onFinishFailed(error?.response?.data.code);
      setLoading(false);
      return;
    }

    notification.success({
      message: (
        'Se han agrupado los productos exitosamente.'
      ),
    });

    setLoading(false);
    onClose(true);
  };
  const handleOk = async (): Promise<void> => {
    const values = await form.validateFields();
    await handleUpdate(values);
  };
  const handleSelect = async (value: any): Promise<void> => {
    const [result, error] = await backend.products.getOne(value);
    if (error || !result || !result.data) {
      message.error('Error al cargar el producto.');
      return;
    }
    const prod: Product = result.data;

    const prod1: any = existingProducts?.find((p) => p.id === value) as Product;
    prod.category = (prod1 as Product).category;
    prod.laboratory = (prod1 as Product).laboratory.name;

    setNewProd(prod);
  };

  const replaceFieldsHint: string[] = [
    'Clave',
    'Nombre',
    'Categoria',
    'Presentación',
    'IEPS',
    'Laboratorio',
    'IVA',
  ];

  const productLabel = (product: Product): string => `${product.key}
    - ${product.category}
     - ${product.name}
     - ${product.presentation}
     - ${product.laboratory?.name}
     - ${Math.round(product.iva)}%`;

  return (
    <Modal
      visible={visible}
      onCancel={() => onClose(false)}
      onOk={handleOk}
      okText="Confirmar"
      cancelText="Cancelar"
      title="Agrupar productos"
      confirmLoading={loading}
      width={900}
    >
      {existingProducts
        ? (
          <>
            <Form
              form={form}
            >

              <Form.Item
                name="product"
                rules={[{ required: true }]}
                label="Producto existente"
              >
                <Select
                  showSearch
                  placeholder="Buscar producto existente"
                  filterOption={
                    (input, option: any) => (
                      option.children.toLowerCase().indexOf(input.toLowerCase())
                      >= 0
                    )
                  }
                  onChange={handleSelect}
                >
                  {Object.values(existingProducts).map(
                    (product) => (
                      <Option value={product.id} key={product.id}>
                        {productLabel(product)}
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>

            </Form>
            <Row style={{ marginTop: '1em' }}>
              <Typography.Text type="secondary">
                ¿Esta seguro que desea realizar esta accion?
                Los siguientes productos seran asociados
                con el producto existente seleccionado:
              </Typography.Text>
            </Row>
            {products?.map((product) => (
              <Row
                justify="center"
                style={{ marginTop: '1em' }}
                key={product.id}
              >
                {productLabel(product)}
              </Row>
            ))}
            <Row style={{ marginTop: '1.5em' }}>
              <Typography.Text type="secondary">
                Los siguientes campos de los productos
                seran remplazados por el producto existente seleccionado:
              </Typography.Text>
            </Row>
            {newProd ? (
              <>
                <Row justify="center" style={{ marginTop: '0.1em' }}>
                  Clave=
                  {newProd?.key}
                </Row>
                <Row justify="center" style={{ marginTop: '0.1em' }}>
                  Nombre=
                  {newProd?.name}
                </Row>
                <Row justify="center" style={{ marginTop: '0.1em' }}>
                  Categoria=
                  {newProd?.category}
                </Row>
                <Row justify="center" style={{ marginTop: '0.1em' }}>
                  Presentacion=
                  {newProd?.presentation}
                </Row>
                <Row justify="center" style={{ marginTop: '0.1em' }}>
                  IVA=
                  {`${Math.floor(newProd?.iva)}%`}
                </Row>
                <Row justify="center" style={{ marginTop: '0.1em' }}>
                  IEPS=
                  {`${Math.floor(newProd?.ieps)}%`}
                </Row>
                <Row justify="center" style={{ marginTop: '0.1em' }}>
                  Laboratorio=
                  {newProd?.laboratory}
                </Row>
              </>
            ) : null}

            {!newProd && replaceFieldsHint?.map((field) => (
              <Row key={field} justify="center" style={{ marginTop: '0.1em' }}>
                {field}
              </Row>
            ))}
          </>
        ) : <LoadingIndicator />}
    </Modal>
  );
};

export default GroupProductModal;
