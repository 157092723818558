import React, { useState, useEffect, useCallback } from 'react';
import {
  Button, Col, notification, Row, Tooltip,
} from 'antd';
import {
  Client, Invoice, Order, Provider,
} from '@types';
import moment from 'moment';
import Table, { Column } from 'components/Table';
import { InvoiceStatus } from 'constants/strings';
import {
  CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useBackend } from 'integrations';
import confirm from 'antd/lib/modal/confirm';
import useAuth from 'hooks/useAuth';
import RejectInvoiceModal from 'components/Modals/RejectInvoiceModal';
import CancelInvoiceModal from 'components/Modals/CancelInvoiceModal';
import InvoiceStatusTag from 'components/InvoiceStatusTag';
import OrderInvoiceStatusTag from 'components/OrderInvoiceStatusTag';
import TableFilter from 'components/TableFilter';
import NumberFormat from 'react-number-format';
import GenerateReportModal from 'components/Modals/GenerateInvoiceReportModal/GenerateInvoiceReportModal';

import { Props } from './InvoiceTable.type';

interface RejectModalProps {
  visible: boolean;
  invoice: Invoice | undefined;
}

interface CancelModalProps {
  visible: boolean;
  invoice: Invoice | undefined;
}

interface GenerateReportModal {
  visible: boolean,
}

const InvoiceTable: React.FC<Props> = (
  { invoices, redirectToOrderDetail, onRefresh },
) => {
  const backend = useBackend();
  const {
    isAdmin, isInvoiceManager, isClient,
    isProvider,
  } = useAuth();
  const [rejectModal, setRejectModal] = useState<RejectModalProps>({
    visible: false,
    invoice: undefined,
  });
  const [cancelModal, setCancelModal] = useState<CancelModalProps>({
    visible: false,
    invoice: undefined,
  });

  const [generateReportModal, setGenerateReportModal] = useState<GenerateReportModal>(
    { visible: false },
  );

  const onCloseModal = (success: boolean): void => {
    setGenerateReportModal({ ...generateReportModal, visible: false });
  };
  const onRestrictedUpdate = async (
    invoice: number, accepted: boolean, reason?: string,
  ): Promise<boolean> => {
    const payload = {
      status: accepted ? InvoiceStatus.ACCEPTED : InvoiceStatus.REJECTED,
      reject_reason: reason,
    };
    const [result, error] = await backend.invoice.patch(
      `/${invoice}/status`, payload,
    );
    if (error || !result) {
      const message = 'Ocurrió un error al modificar el estado de la factura.';
      let description = '';

      switch (error?.response?.data.code) {
        case 'UNAVAILABLE_WEEKDAY':
          description = 'Hoy no es posible aprobar o desaprobar facturas. Solo martes y miercoles.';
          break;

        default:
          description = 'Intentalo más tarde';
          break;
      }
      notification.error({
        message,
        description,
      });
      return false;
    }
    notification.success({
      message: accepted
        ? 'La factura ha cambiado a estado "Aceptado" exitosamente.'
        : 'La factura ha cambiado a estado "Rechazado" exitosamente.',
    });
    onRefresh();
    return true;
  };

  const onReject = async (invoice: Invoice, values: any): Promise<boolean> => {
    const success = await onRestrictedUpdate(invoice.id, false, values.reject_reason);
    return success;
  };

  const onRejectModalClose = (): void => {
    setRejectModal({
      visible: false,
      invoice: undefined,
    });
  };

  const onOpenUpdate = async (
    invoice: number, reason?: string,
  ): Promise<boolean> => {
    const payload = {
      status: InvoiceStatus.CANCELLED,
      cancel_reason: reason,
    };
    const [result, error] = await backend.invoice.patch(
      `/${invoice}/cancel`, payload,
    );
    if (error || !result) {
      const message = 'Ocurrió un error al modificar el estado de la factura.';
      let description = '';

      switch (error?.response?.data.code) {
        default:
          description = 'Intentalo más tarde';
          break;
      }
      notification.error({
        message,
        description,
      });
      return false;
    }
    notification.success({
      message: 'La factura ha cambiado a estado "Cancelada" exitosamente.',
    });
    onRefresh();
    return true;
  };

  const onCancel = async (invoice: Invoice, values: any): Promise<boolean> => {
    const success = await onOpenUpdate(invoice.id, values.cancel_reason);
    return success;
  };

  const onCancelModalClose = (): void => {
    setCancelModal({
      visible: false,
      invoice: undefined,
    });
  };

  const [filtered, setFiltered] = useState<Invoice[]>([]);

  const resetFiltered = useCallback(
    () => setFiltered(invoices || []), [invoices],
  );

  const onFilterAny = (
    data: Invoice[], value: string,
  ): Invoice[] => data.filter((invoice) => (
    (
      invoice.client.toLowerCase().includes(
        value.toLocaleLowerCase(),
      )
    )
    || (
      invoice.status.toLowerCase().includes(
        value.toLocaleLowerCase(),
      )
    )
  ));

  const renderInvoiceDate = (
    invoice: Invoice,
  ): any => (
    <Row>
      {moment(invoice.invoice_date).format('YYYY-MM-DD')}
    </Row>
  );

  const renderCreatedDate = (
    invoice: Invoice,
  ): any => (
    <Row>
      {moment(invoice.created_at).format('YYYY-MM-DD')}
    </Row>
  );

  const renderData = (): any => (
    invoices?.map((invoice) => ({
      invoice_folio: invoice.invoice_folio,
      invoice_date: invoice.invoice_date,
      order: (invoice.order as Order).id,
      provider_key: ((invoice.order as Order).provider as Provider)?.nav_key,
      client_key: ((invoice.order as Order).client as Client)?.internal_key,
      client_dimension: ((invoice.order as Order).client as Client)?.dimension,
      provider_dimension: ((invoice.order as Order).provider as Provider)?.id,
    }))
  );

  useEffect(() => {
    resetFiltered();
  }, [invoices, resetFiltered]);

  const columns = [
    {
      title: 'Folio de Factura',
      dataIndex: 'invoice_folio',
      key: 'invoice_folio',
    },
    {
      title: 'Folio de refactura',
      dataIndex: 'reinvoice_folio',
      key: 'reinvoice_folio',
    },
    {
      title: 'Fecha de Factura',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      width: 90,
      render: (
        _: number, invoice: Invoice,
      ) => renderInvoiceDate(invoice),
    },
    {
      title: 'Pedido',
      dataIndex: 'order',
      key: 'order',
      render: (_: number, invoice: Invoice) => (redirectToOrderDetail ? (
        <Button
          target="_blank"
          type="link"
          size="small"
          href={`/pedidos/${(invoice.order as Order).id}`}
        >
          {`${(invoice.order as Order).id}`}
        </Button>
      ) : `${(invoice.order as Order).id}`),
    },
    {
      title: 'Proveedor',
      dataIndex: 'provider',
      key: 'provider',
      render: (_: number, invoice: Invoice) => (
        `${((invoice.order as Order).provider as Provider)?.internal_key} - ${((invoice.order as Order).provider as Provider)?.name}`
      ),
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      render: (_: number, invoice: Invoice) => (
        `${((invoice.order as Order).client as Client)?.internal_key} - ${((invoice.order as Order).client as Client)?.name}`
      ),
    },
    {
      title: 'Importe MN',
      dataIndex: 'amount',
      key: 'amount',
      render: (
        _: number, invoice: Invoice,
      ) => (
        <Row>
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={invoice.amount}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Row>
      ),
    },
    {
      title: 'Fecha de Captura',
      dataIndex: 'created_at',
      key: 'created_at',
      defaultSortOrder: 'descend',
      width: 90,
      sorter: (a: any, b: any) => moment(
        a.created_at,
      ).unix() - moment(b.created_at).unix(),
      render: (
        _: number, invoice: Invoice,
      ) => renderCreatedDate(invoice),
    },
    {
      title: 'Fecha de Entrega',
      dataIndex: 'delivery_date',
      key: 'delivery_date',
      width: 90,
    },
    {
      title: 'Archivo XML',
      dataIndex: 'xml_file',
      key: 'xml_file',
      render: (_: number, invoice: Invoice) => (
        <Button
          target="_blank"
          type="link"
          size="small"
          href={invoice.xml_file}
        >
          Abrir archivo
        </Button>
      ),
    },
    {
      title: 'Archivos Adicionales',
      dataIndex: 'extra_files',
      key: 'extra_files',
      render: (_: number, invoice: Invoice) => (
        <Col>
          <Row>
            <Button
              target="_blank"
              type="link"
              size="small"
              href={invoice.invoice_file}
            >
              Abrir archivo
            </Button>
          </Row>
          {invoice.extra_file ? (
            <Row>
              <Button
                target="_blank"
                type="link"
                size="small"
                href={invoice.extra_file}
              >
                Abrir archivo
              </Button>
            </Row>
          ) : null}
        </Col>
      ),
    },
    {
      title: 'Estado de la factura',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <InvoiceStatusTag status={status} />
      ),
    },
    {
      title: 'Estado total de facturacion del pedido',
      dataIndex: 'status',
      key: 'status',
      render: (_: number, invoice: Invoice) => (
        <OrderInvoiceStatusTag status={(invoice.order as Order).invoice_status} />
      ),
    },
    {
      title: 'Razon de rechazo',
      dataIndex: 'reject_reason',
      key: 'reject_reason',
    },
    {
      title: 'Razon de cancelacion',
      dataIndex: 'cancel_reason',
      key: 'cancel_reason',
    },
    {
      title: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (uuid: string) => (
        <Tooltip title={uuid}>
          <Row style={{ width: 'max-content' }}>
            {uuid.substring(0, 7)}
            ...
          </Row>
        </Tooltip>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: number, data: Invoice) => {
        const canUpdate = data.can_update_status_today;
        const difference = Math.abs((data.order as Order).invoice_total - ((data.order as Order).total as number));
        const notFull = difference > 2;
        const isPending = data.status === InvoiceStatus.PENDING;
        const canCancel = ((data.order as Order).status !== 'Cancelado') && data.status === InvoiceStatus.PENDING;
        const acceptModalTitle = `
        Aceptar factura con uuid ${data.uuid}`;
        const acceptModalContent = 'Se cambiara el estado de '
          + ' la factura a "Aceptado"';
        let acceptTooltip;
        if (!canUpdate) {
          acceptTooltip = 'Esta acción no se puede realizar';
        } else if (notFull) {
          acceptTooltip = 'No puedes aceptar hasta que se suban todas las facturas correspondientes';
        } else if (isPending) {
          acceptTooltip = `Aceptar factura uuid ${data.uuid}.`;
        } else {
          acceptTooltip = 'Esta factura ya ha sido sido aceptada o rechazada.';
        }
        let rejectTooltip;
        if (!canUpdate) {
          rejectTooltip = 'Esta accion no se puede realizar';
        } else if (notFull) {
          rejectTooltip = 'No puedes aceptar hasta que se suban todas las facturas correspondientes';
        } else if (isPending) {
          rejectTooltip = `Rechazar factura uuid ${data.uuid}.`;
        } else {
          rejectTooltip = acceptTooltip;
        }
        let cancelTooltip;
        if (!canUpdate) {
          cancelTooltip = 'Esta accion no se puede realizar';
        } else if (canCancel) {
          cancelTooltip = `Cancelar factura uuid ${data.uuid}.`;
        } else {
          cancelTooltip = 'Esta factura no puede ser cancelada';
        }
        return !isProvider ? (
          <Row>
            <Tooltip title={acceptTooltip}>
              <Button
                style={{ background: '#03470a', color: 'white' }}
                shape="circle"
                icon={<CheckCircleOutlined />}
                disabled={!canUpdate || !isPending || notFull}
                onClick={() => {
                  confirm({
                    title: acceptModalTitle,
                    icon: <ExclamationCircleOutlined />,
                    content: acceptModalContent,
                    okText: 'Aceptar',
                    cancelText: 'Cancelar',
                    onOk() {
                      return onRestrictedUpdate(data.id, true);
                    },
                  });
                }}
              />
            </Tooltip>
            <Tooltip style={{ display: 'ruby' }} title={rejectTooltip}>
              <Button
                shape="circle"
                style={{ background: '#610101', borderColor: '#610101', color: 'white' }}
                icon={<CloseCircleOutlined />}
                disabled={!canUpdate || !isPending || notFull}
                onClick={() => {
                  setCancelModal({
                    visible: true,
                    invoice: data,
                  });
                }}
              />
            </Tooltip>
          </Row>

        ) : (
          <Row>
            <Tooltip style={{ display: 'ruby' }} title={cancelTooltip}>
              <Button
                shape="circle"
                style={{ background: '#610101', borderColor: '#610101', color: 'white' }}
                icon={<CloseCircleOutlined />}
                disabled={!canUpdate || !canCancel}
                onClick={() => {
                  setCancelModal({
                    visible: true,
                    invoice: data,
                  });
                }}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const getColumns = (): Column[] => {
    if (isAdmin || isInvoiceManager || isClient) {
      return columns;
    }
    const providerCol = ['Proveedor', 'Clave del Proveedor'];
    const clientCol = ['Cliente', 'Clave del Cliente'];
    return columns.filter((col) => (isClient ? !clientCol.includes(col.title) : !providerCol.includes(col.title)));
  };

  return (
    <>
      <TableFilter
        fieldsToFilter={[
          { key: 'status', value: 'Estado de la factura' },
          { key: 'delivery_date', value: 'Fecha de captura' },
          { key: 'invoice_folio', value: 'Folio' },
          { key: 'uuid', value: 'UUID' },
        ]}
        customFieldsToFilter={[
          { key: 'order.provider.name', value: 'Proveedor' },
          { key: 'order.client.name', value: 'Cliente' },
          { key: 'order.invoice_status', value: 'Estado total de facturacion' },
        ]}
        onFilter={setFiltered}
        filterAny={onFilterAny}
        // eslint-disable-next-line
        data={invoices!}
      />
      {isAdmin ? (
        <>
          <Row
            gutter={16}
            justify="end"
            style={{
              marginTop: 10,
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            <Col className="gutter-row" span={6}>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                block
                onClick={() => (
                  setGenerateReportModal({
                    visible: true,
                  }))}
              >
                Descargar Reporte
              </Button>
            </Col>
          </Row>
          <GenerateReportModal
            visible={generateReportModal.visible}
            onClose={onCloseModal}
          />
        </>
      ) : null}
      <Table
        rowKey={(row) => `${row.id}`}
        data={invoices ? filtered : []}
        columns={
          getColumns()
        }
        pagination={{ position: ['topCenter', 'bottomCenter'] }}
      />
      {rejectModal.invoice ? (
        <RejectInvoiceModal
          onOk={onReject}
          invoice={rejectModal.invoice}
          visible={rejectModal.visible}
          onClose={onRejectModalClose}
        />
      ) : null}
      {cancelModal.invoice ? (
        <CancelInvoiceModal
          onOk={onCancel}
          invoice={cancelModal.invoice}
          visible={cancelModal.visible}
          onClose={onCancelModalClose}
        />
      ) : null}
    </>
  );
};
export default InvoiceTable;
