import { Layout } from 'antd';
import styled from 'styled-components';
import LogoImage from 'static/img/logo.png';

export const Sider = styled(Layout.Sider)`
  box-shadow: 0px 1px 3px rgba(0, 0, 0, .05), 0px 0px 5px rgba(0, 0, 0, .03);
  
  .ant-menu {
    background-color: ${({ theme }) => theme.colors.bgContent};
  }
`;

export const Logo = styled.div`
  background-image: url(${LogoImage});
  background-repeat: no-repeat;
  background-size: contain;
  transform: scale(1.2); // this is just to adjust the image size
`;
