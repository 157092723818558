import React, { useEffect } from 'react';
import {
  Form, Input,
} from 'antd';
import FormButton from 'components/FormButton';
import Logo from 'static/img/logoBlue.png';
import Props from './LoginFormulary.type';

const LoginFormulary: React.FC<Props> = ({
  initialState,
  onFinish,
  onFinishFailed,
  form,
  isLoading,
}) => {
  useEffect(() => {
    form.setFieldsValue({ ...initialState });
  }, [form, initialState]);

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      name="loginFormulary"
      initialValues={initialState}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className="w3-container">
        <div className="w3-center"><img className="w3-image w3-center" style={{ width: 150, height: 90, background: '#004a7' }} src={Logo} alt="logo" /></div>
        <br />
        <br />
      </div>
      <Form.Item
        name="username"
        label="Nombre de usuario"
        rules={[{ required: true }]}
      >
        <Input
          placeholder="Introducir nombre de usuario"
        />
      </Form.Item>
      <Form.Item
        name="password"
        label="Contraseña"
        rules={[{ required: true }]}
      >
        <Input.Password
          placeholder="Introducir contraseña"
        />
      </Form.Item>

      <FormButton
        loading={isLoading}
        text="Confirmar"
      />
    </Form>
  );
};

export default LoginFormulary;
