import { Popconfirm, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import routes from 'Routes';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/esm/Container';
import Exit from 'static/img/flecha.png';
import UserIcon from 'static/img/acceso.png';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import {
  HeaderCont,
  RightContainer,
} from './Header.styled';

const LoginBtn: React.FC = () => {
  const { user, logout } = useAuth();
  const [canLogin, setCanLogin] = useState(true);
  useEffect(() => {
    setCanLogin(!user);
  }, [user]);

  if (!canLogin) {
    return (
      <Popconfirm
        title="¿Deseas cerrar sesión?"
        onConfirm={logout}
        okText="Sí"
        cancelText="No"
        placement="bottomRight"
      >
        <Typography.Link>
          <img src={Exit} alt="salida" style={{ maxHeight: 30, margin: 3 }} />
        </Typography.Link>
      </Popconfirm>
    );
  }

  return (
    <Link to={routes.otherRoutes.routes.login.path} component={Typography.Link}>
      Iniciar Sesión
    </Link>
  );
};

const FirstName: React.FC = () => {
  const { user } = useAuth();
  if (!user || !user.first_name) {
    return null;
  }

  const Espacio = ' ';

  return (
    <Typography className="firstname" style={{ background: '#003366', color: '#fff' }}>
      {user.first_name}
      {Espacio}
      {user.last_name}
    </Typography>
  );
};

const LastName: React.FC = () => {
  const { user } = useAuth();
  if (!user || !user.first_name) {
    return null;
  }

  if (!user || !user.last_name) {
    return null;
  }

  const Espacio = ' ';

  return (
    <Typography className="firstname" style={{ background: '#003366', color: '#fff' }}>
      {user.first_name}
      {Espacio}
      {user.last_name}
    </Typography>
  );
};

const UserName: React.FC = () => {
  const { user } = useAuth();
  if (!user || !user.username) {
    return null;
  }

  const Espacio = '  ';

  return (
    <Typography className="username" style={{ background: '#003366', color: '#fff' }}>
      <img src={UserIcon} alt="usuario" style={{ maxHeight: 45, margin: 3 }} />
      {Espacio}
      {user.username}
      {Espacio}
      <LoginBtn />
    </Typography>
  );
};

const Header: React.FC = () => (
  <Navbar style={{ background: '#003366', color: '#fff', height: 108 }}>
    <Container style={{ marginLeft: 0 }}>
      <Navbar.Brand style={{ paddingLeft: 20, alignContent: 'initial' }}>
        <FirstName />
      </Navbar.Brand>
      <Navbar.Collapse
        className="justify-content-end"
        style={
        {
          background: '#003366', color: '#fff', position: 'absolute', right: 0, paddingRight: 30,
        }
        }
      >
        <Navbar.Text>
          <UserName />
        </Navbar.Text>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default Header;
