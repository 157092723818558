import {
  CheckCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  StopOutlined,
} from '@ant-design/icons';
import {
  Client, Provider, User, Group,
} from '@types';
import { Button, notification, Tooltip } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import confirm from 'antd/lib/modal/confirm';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import Table from 'components/Table';
import TableFilter from 'components/TableFilter';
import Title from 'components/Title';
import useAuth, { UserGroups } from 'hooks/useAuth';
import { useBackend } from 'integrations';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Actions } from './Users.ListUsers.styled';

const ListUsers: React.VC = ({ verboseName, parentName }) => {
  const backend = useBackend();
  const history = useHistory();
  const auth = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const { isAdmin } = useAuth();

  const [filtered, setFiltered] = useState<User[]>([]);

  const shouldAllowModifyUser = isAdmin;

  const resetFiltered = useCallback(
    () => setFiltered(users || []), [users],
  );

  const fetchUsers = useCallback(async () => {
    setLoading(true);

    const [result, error] = await backend.users.getAll();

    if (error || !result) {
      notification.error({
        message: 'Ocurrió un error al cargar la lista de usuarios!',
        description: 'Intentalo más tarde',
      });
      setLoading(false);
      return;
    }
    setUsers(result.data);

    setLoading(false);
  }, [backend.users]);

  const onFilterAny = (
    data: User[], value: string,
  ): User[] => data.filter((product) => (
    product.first_name.toLowerCase().includes(
      value.toLowerCase(),
    )
    || product.email.toLowerCase().includes(
      value.toLowerCase(),
    )
    || product.last_name.toLowerCase().includes(
      value.toLowerCase(),
    )
    || product.username.toLowerCase().includes(
      value.toLowerCase(),
    )
  ));

  const updateUserActive = async (
    userId: number, activate: boolean,
  ): Promise<void> => {
    const payload = {
      is_active: activate,
    };
    const [result, error] = await backend.users.put(
      `/${userId}/active`, payload,
    );
    if (error || !result) {
      notification.error({
        message: 'Ocurrió un error al modificar el acceso del usuario!',
        description: 'Intentalo más tarde',
      });
    }
    notification.success({
      message: !activate ? 'El usuario se ha desactivado exitosamente.'
        : 'El usuario se ha activado exitosamente.',
    });
    fetchUsers();
  };

  useEffect(() => {
    fetchUsers();
  }, [history, fetchUsers]);

  const ACTIVE = 'Activo';
  const INACTIVE = 'No activo';
  const NOT_APPLICABLE = 'N/A';
  const NO_DATA = 'Sin datos';

  const columns = [
    {
      title: 'Nombre1',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a: any, b: any) => a.first_name.localeCompare(b.name),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Nombre2',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a: any, b: any) => a.last_name.localeCompare(b.name),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Rol',
      dataIndex: 'group',
      key: 'group',
      sorter: (a: any, b: any) => a.group.localeCompare(b.group),
      defaultSortOrder: 'ascend',
      render: (_: number, user: User) => (
        ((user as User).groups[0] as unknown as Group)?.name
      ),
    },
    {
      title: 'Nombre Comercial',
      dataIndex: 'com_name',
      key: 'com_name',
      render: (_:number, user: User) => (
        user.provider?.name || user.client?.name || user.laboratory?.name || '-'
      ),
    },
    {
      title: 'Correo',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    },
    {
      title: 'Nombre de usuario',
      dataIndex: 'username',
      key: 'username',
      sorter: (a: any, b: any) => a.username.localeCompare(b.username),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Último inicio de sesión',
      dataIndex: 'last_login',
      key: 'last_login',
      sorter: (a: any, b: any) => moment(
        a.last_login,
      ).unix() - moment(b.last_login).unix(),
    },
    {
      title: 'Fecha de registro',
      dataIndex: 'date_joined',
      key: 'date_joined',
      sorter: (a: any, b: any) => moment(
        a.date_joined,
      ).unix() - moment(b.date_joined).unix(),
    },
    {
      title: 'Activo',
      dataIndex: 'is_active',
      key: 'is_active',
      sorter: (a: any, b: any) => a.active.localeCompare(b.active),
      defaultSortOrder: 'ascend',
      render: (_: number, user: User) => (
        user.is_active ? ACTIVE : INACTIVE
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      render: (_: number, data: any) => {
        const active = (data.is_active);
        const title = active
          ? `Desactivar usuario ${data.username}`
          : `Activar usuario ${data.username}`;

        const content = active
          ? 'Se deshabilitará el acceso al usuario y no'
          + ' podrá ingresar al sistema nuevamente.'
          : 'Se habilitará el acceso al usuario y'
          + ' podrá ingresar nuevamente';

        const isMe = auth.user?.id === data.id;
        const toolTipTitle = active ? 'Desactivar usuario' : 'Activar usuario';

        return (
          <Actions>
            <Tooltip title={
              isMe
                ? 'No está permitido realizar esta acción con su propia cuenta.'
                : toolTipTitle
            }
            >
              <Button
                shape="circle"
                icon={active ? <CheckCircleOutlined /> : <StopOutlined />}
                disabled={auth.user?.id === data.id}
                onClick={() => {
                  confirm({
                    title,
                    icon: <ExclamationCircleOutlined />,
                    content,
                    okText: 'Confirmar',
                    okType: 'danger',
                    cancelText: 'Cancelar',
                    onOk() {
                      return updateUserActive(data.id, !active);
                    },
                  });
                }}
              />
            </Tooltip>
            {shouldAllowModifyUser && (
              <Tooltip title="Editar usuario">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    history.push(`/usuarios/${data.id}/modificar`);
                  }}
                />
              </Tooltip>
            )}
          </Actions>
        );
      },
    },
  ];

  const getBusinessName = (user: User): string => {
    if (UserGroups.CLIENT === user.groups[0] && user.client) {
      return user.client.name;
    }
    if (UserGroups.PROVIDER === user.groups[0] && user.provider) {
      return user.provider.name;
    }
    return NOT_APPLICABLE;
  };

  const handleButton = (): void => {
    history.replace('/usuarios/nuevo');
  };

  useEffect(() => {
    resetFiltered();
  }, [users, resetFiltered]);

  return (
    <Content>
      <Title viewName={verboseName} parentName={parentName} />
      {isLoading || !users ? <LoadingIndicator /> : (
        <>
          <TableFilter
            useAny
            fieldsToFilter={[
              { key: 'first_name', value: 'Nombre1' },
              { key: 'last_name', value: 'Nombre2' },
              { key: 'email', value: 'Correo' },
              { key: 'username', value: 'Nombre de usuario' },
            ]}
            onFilter={setFiltered}
            filterAny={onFilterAny}
            data={users}
          />
          <Table
            rowKey={(row) => `${row.id}`}
            data={filtered}
            columns={columns}
            actions={[
              {
                action: handleButton,
                text: 'Nuevo',
                icon: <PlusOutlined />,
              },
            ]}
          />
        </>
      )}
    </Content>
  );
};

export default ListUsers;
