import React from 'react';
import {
  Typography, Row, Col, Card,
} from 'antd';
import moment from 'moment';
import OrderStatusTag from 'components/OrderStatusTag';
import {
  Client, Provider, UserEmail, Profile,
} from '@types';
import NumberFormat from 'react-number-format';
import { Props } from './OrderSummary.type';

const { Title, Text } = Typography;

interface RowType {
  label: string;
  value: string | undefined;
}

const OrderSummaryPrint: React.FC<Props> = ({ order }) => {
  const renderRow = (label: string, value?: string): any => {
    const valueText = value || 'Sin datos';
    const renderValue = (v: any): any => {
      switch (label) {
        case 'Total':
          return (
            <NumberFormat
              thousandSeparator=","
              thousandsGroupStyle="thousand"
              displayType="text"
              value={v}
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
            />
          );
        case 'Estado':
          return (
            <Row>
              <OrderStatusTag status={v} />
            </Row>
          );
        default:
          return v;
      }
    };
    return (
      <Row
        key={label}
      >
        <Col span={10} style={{ textAlign: 'right', marginRight: '30px' }}>
          <Text strong>
            {label}
          </Text>
        </Col>
        <Col span={11}>
          <Text strong>
            {renderValue(valueText)}
          </Text>
        </Col>
      </Row>
    );
  };

  const rows: RowType[] = [
    {
      label: 'RFC',
      value: ((order.client as Client)?.rfc),
    },
    {
      label: 'Razon Social',
      value: ((order.client as Client)?.name),
    },
    {
      label: 'Domicilio fiscal',
      value: ((order.client as Client)?.fiscal_address),
    },
    {
      label: 'Domicilio de entrega',
      value: ((order.client as Client)?.delivery_address),
    },
    {
      label: 'Telefono del cliente',
      value: ((order.client as Client)?.invoice_telephone),
    },
  ];

  const rows2: RowType[] = [
    {
      label: 'Proveedor',
      value: (order.provider as Provider)?.name,
    },
    {
      label: 'Correo del proveedor',
      value: ((order.provider as Provider)?.emails?.filter((email) => email.category === 'Pedidos').pop() as UserEmail)?.email,
    },
    {
      label: 'Telefono del proveedor',
      value: ((order.provider as Provider)?.phones?.pop() as Profile)?.telephone,
    },
    {
      label: 'Fecha de entrega',
      value: order.status === 'Aceptado' ? moment(order.updated_at).format('YYYY-MM-DD') : '',
    },
  ];

  const rows3: RowType[] = [
    {
      label: 'Pedido realizado por',
      value: (order.user as string),
    },
    {
      label: 'Total',
      value: `$${order.total?.toFixed(2)}`,
    },
    {
      label: 'Fecha del pedido',
      value: moment(order.created_at).format('YYYY-MM-DD'),
    },
    {
      label: 'Comentario',
      value: order.comment,
    },
    {
      label: 'Estado',
      value: order.status,
    },
  ];
  return (
    <Card>
      <Row justify="center">
        <Title level={3}>
          PEDIDO No.
          {' '}
          {order.id}
        </Title>
      </Row>
      {
        rows.map((row) => renderRow(
          row.label, row.value,
        ))
      }
      <Row>
        -
      </Row>
      {
        rows2.map((row) => renderRow(
          row.label, row.value,
        ))
      }
      <Row>
        -
      </Row>
      {
        rows3.map((row) => renderRow(
          row.label, row.value,
        ))
      }
    </Card>
  );
};

export default OrderSummaryPrint;
