import { FileDoneOutlined, ToolOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RegisteredGroup, RoutesType } from 'Routes';
import LogoImage from 'static/img/logo.png';
import { Sider } from './SideMenu.styled';
import HeaderGaqsa from '../Header/index';

const { SubMenu } = Menu;

const SideMenu: React.FC<{ groups: RegisteredGroup }> = ({ groups }) => {
  const auth = useAuth();

  const { user } = auth;
  if (!user) {
    return null;
  }

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
      style={{ background: '#003366' }}
    >
      <div style={{ margin: 9, alignContent: 'center' }}><img style={{ height: 90 }} src={LogoImage} alt="logo" /></div>
      <Menu
        mode="inline"
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        style={{ height: '100%', borderRight: 0 }}
        id="mySideBar"
      >
        {Object.values(groups).map(
          (group) => {
            const groupRoutes = Object.values(group.routes);

            const visibibleRoutes = groupRoutes.filter(
              (route) => (route.showInMenu
                && (route.hasAccess ? route.hasAccess(auth) : true)),
            );

            if (!group.showInMenu || visibibleRoutes.length <= 0) {
              return null;
            }

            const Icon = group.icon || FileDoneOutlined;

            return (
              <SubMenu
                key={`${group.verboseName}-subMenu`}
                icon={<Icon />}
                title={group.verboseName}
              >
                {visibibleRoutes.map(
                  (route) => {
                    if (!route.showInMenu || (
                      route.hasAccess && !route.hasAccess(auth))) {
                      return null;
                    }

                    const { path, verboseName }: RoutesType = route;

                    return (
                      <Menu.Item key={`${verboseName}-menu-item`}>
                        <Link to={path}>
                          {verboseName}
                        </Link>
                      </Menu.Item>
                    );
                  },
                )}
              </SubMenu>
            );
          },
        )}
        {auth.isClient ? (
          <Menu.Item key="herramientas-menu-item" icon={<ToolOutlined />}>
            <Link to="/maquinaria">
              Maquinaria agricola
            </Link>
          </Menu.Item>
        )
          : null}
      </Menu>
    </Sider>
  );
};

export default SideMenu;
