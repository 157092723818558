import React, { useState } from 'react';
import Title from 'components/Title';
import OrderSummary from 'components/OrderSummary';
import NumberFormat from 'react-number-format';
import { Content } from 'antd/lib/layout/layout';
import {
  Laboratory, Order, Product, Requisition,
} from '@types';
import { Table } from 'components/Table/Table.styled';
import Table1 from 'components/Table';
import {
  InputNumber, Form, notification, Row,
} from 'antd';
import FormButton from 'components/FormButton';
import { useBackend } from 'integrations';
import { useHistory } from 'react-router';
import useAuth from 'hooks/useAuth';
import OrdersListRequisitions from 'views/Orders.ListRequisitions';
import RequisitionStatusTag from 'components/RequisitionStatusTag';

interface Props {
  order: Order;
}

interface OrderUpdateForm {
  quantity_accepted: number;
  requisition: number;
  sent: boolean;
}

const OrderUpdate: React.FC<Props> = ({ order }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const backend = useBackend();
  const history = useHistory();
  const initialSentRequisitons = order.requisitions.filter(
    (requisition) => requisition.sent,
  );
  const initialSelected = initialSentRequisitons.map(
    (requisition) => requisition.id,
  );
  const [currentData, setCurrentData] = useState(order?.requisitions.map((requisition, index) => ({
    no: index + 1,
    key: (requisition.product as Product).key,
    active_substance: (requisition.product as Product).active_substance,
    presentation: (requisition.product as Product).presentation,
    laboratory: ((requisition.product as Product).laboratory as Laboratory)?.name,
    iva: (requisition.product as Product).iva,
    ieps: (requisition.product as Product).ieps,
    id: requisition.id,
    order: order.id.toString(),
    product: (requisition.product as Product).name,
    quantity_requested: requisition.quantity_requested,
    quantity_accepted: requisition.quantity_accepted,
    price: requisition.price,
    amount: (requisition.price * requisition.quantity_accepted),
    status: requisition.status,
  })));
  const [
    selectedRowKeys,
    setSelected,
  ] = useState(initialSelected);
  let rows1: any;
  const onSelectChange = (rows: any): void => {
    setSelected(rows);
    rows1 = rows;
  };
  let subtotal = 0;
  let iva = 0;
  let ieps = 0;
  let total = 0;
  let flag = 1;
  const [subtotalT, setSubtotalT] = useState(0);
  const [ivaT, setIvaT] = useState(0);
  const [iepsT, setIepsT] = useState(0);
  const [totalT, setTotalT] = useState(0);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnTitle: 'Surtido',
  };

  let groupTxt: string;
  let groupArray: string[];
  const { user } = useAuth();
  if (!user || !user.groups) {
    groupTxt = 'NoGroup';
  } else {
    groupArray = user.groups;
    for (let i = 0; i < groupArray.length; i += 1) {
      if (i === 0) {
        groupTxt = user.groups[i];
      }
    }
  }
  const calcSummary = (id?: number, quantity?: number): any => {
    if (flag) {
      const data = order?.requisitions.map((requisition, index) => ({
        no: index + 1,
        key: (requisition.product as Product).key,
        active_substance: (requisition.product as Product).active_substance,
        presentation: (requisition.product as Product).presentation,
        laboratory: ((requisition.product as Product).laboratory as Laboratory)?.name,
        iva: (requisition.product as Product).iva,
        ieps: (requisition.product as Product).ieps,
        id: requisition.id,
        order: order.id.toString(),
        product: (requisition.product as Product).name,
        quantity_requested: requisition.quantity_requested,
        quantity_accepted: requisition.quantity_accepted,
        price: requisition.price,
        amount: (requisition.price * requisition.quantity_accepted),
        status: requisition.status,
      }));
      currentData?.forEach((element) => {
        subtotal += element.amount;
        iva += (element.amount * (element.iva / 100));
        ieps += (element.amount * (element.ieps / 100));
      });
      total = subtotal + iva + ieps;
      flag = 0;
    }
  };

  const drawSummary = (): any => (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} />
        <Table.Summary.Cell index={4} />
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} />
        <Table.Summary.Cell index={7} />
        <Table.Summary.Cell index={8} />
        <Table.Summary.Cell index={9} />
        <Table.Summary.Cell index={10} />
        <Table.Summary.Cell index={11}>Subtotal</Table.Summary.Cell>
        <Table.Summary.Cell index={12} />
        <Table.Summary.Cell index={13} align="right">
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={subtotal}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} />
        <Table.Summary.Cell index={4} />
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} />
        <Table.Summary.Cell index={7} />
        <Table.Summary.Cell index={8} />
        <Table.Summary.Cell index={9} />
        <Table.Summary.Cell index={10} />
        <Table.Summary.Cell index={11}>IVA</Table.Summary.Cell>
        <Table.Summary.Cell index={12} />
        <Table.Summary.Cell index={13} align="right">
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={iva}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} />
        <Table.Summary.Cell index={4} />
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} />
        <Table.Summary.Cell index={7} />
        <Table.Summary.Cell index={8} />
        <Table.Summary.Cell index={9} />
        <Table.Summary.Cell index={10} />
        <Table.Summary.Cell index={11}>IEPS</Table.Summary.Cell>
        <Table.Summary.Cell index={12} />
        <Table.Summary.Cell index={13} align="right">
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={ieps}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2} />
        <Table.Summary.Cell index={3} />
        <Table.Summary.Cell index={4} />
        <Table.Summary.Cell index={5} />
        <Table.Summary.Cell index={6} />
        <Table.Summary.Cell index={7} />
        <Table.Summary.Cell index={8} />
        <Table.Summary.Cell index={9} />
        <Table.Summary.Cell index={10} />
        <Table.Summary.Cell index={11}>Total</Table.Summary.Cell>
        <Table.Summary.Cell index={12} />
        <Table.Summary.Cell index={13} align="right">
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={total}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
  const handleChange = (id?: number, quantity?: number): void => {
    const tempData = currentData;
    if (id !== undefined && quantity !== undefined) {
      tempData[id].quantity_accepted = quantity;
      tempData[id].amount = tempData[id].price * tempData[id].quantity_accepted;
    }
    let s = 0;
    let iv = 0;
    let ie = 0;
    let to = 0;
    tempData?.forEach((element) => {
      s += element.amount;
      iv += (element.amount * (element.iva / 100));
      ie += (element.amount * (element.ieps / 100));
    });
    to = s + iv + ie;
    setSubtotalT(s);
    setIvaT(iv);
    setIepsT(ie);
    setTotalT(to);
    setCurrentData(tempData);
  };
  const renderSummary = (): any => {
    calcSummary();
    handleChange();
    return drawSummary();
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Clave',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Sustancia Activa',
      dataIndex: 'active_substance',
      key: 'active_substance',
    },
    {
      title: 'Presentacion',
      dataIndex: 'presentation',
      key: 'presentation',
    },
    {
      title: 'Laboratorio',
      dataIndex: 'laboratory',
      key: 'laboratory',
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (price: number) => (
        <Row>
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={price}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Row>
      ),
    },
    {
      title: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      render: (iva1: number) => (
        <Row>
          {`${Math.floor(iva1)}%`}
        </Row>
      ),
    },
    {
      title: 'IEPS',
      dataIndex: 'ieps',
      key: 'ieps',
      render: (ieps1: number) => (
        <Row>
          {`${Math.floor(ieps1)}%`}
        </Row>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Row style={{ textAlign: 'center', color: 'initial' }}>
          <RequisitionStatusTag status={status} />
        </Row>
      ),
    },
    {
      title: 'Cantidad Solicitada',
      dataIndex: 'quantity_requested',
      key: 'quantity_requested',
      render: (c1: number) => (
        <Row>
          {c1}
        </Row>
      ),
    },
    {
      title: 'Cantidad a enviar',
      dataIndex: 'quantity_accepted',
      key: 'quantity_accepted',
      render: (_: string, data: any, index: number) => (
        <>
          <Form.Item
            name={['requisitions', index, 'quantity_accepted']}
            initialValue={data.quantity_accepted}
            style={{ margin: 0 }}
          >
            <InputNumber
              max={data.quantity_requested}
              min={0}
              style={{ paddingLeft: 10 }}
              disabled={groupTxt === 'Administrador'}
              onBlur={(event) => handleChange(index, Number(event.target.value))}
            />
          </Form.Item>
          <Form.Item
            hidden
            initialValue={data.id}
            style={{ display: 'none' }}
            name={['requisitions', index, 'requisition']}
          />
        </>
      ),
    },
    {
      title: 'Importe',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => (
        <Row>
          <NumberFormat
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            displayType="text"
            value={amount}
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        </Row>
      ),
    },
  ];

  const onSubmit = async (): Promise<void> => {
    const values = await form.validateFields();
    onSelectChange(selectedRowKeys);
    if (order.requisitions.length > rows1.length) {
      if (window.confirm('Hay productos pendientes por confirmar surtido, continuar?')) {
        Boolean(1);
      }
    }
    const payload = values.requisitions.map((data: OrderUpdateForm) => ({
      ...data,
      sent: selectedRowKeys.find(
        (id: number) => id === data.requisition,
      ) !== undefined,
    }));
    setLoading(true);
    const [, error] = await backend.orders.patch(
      `${order.id}/update`,
      payload,
    );
    if (error) {
      notification.error({
        message: 'Ocurrio un error al enviar los cambios.',
      });
    } else {
      notification.success({
        message: 'Se ha modificado el pedido exitosamente.',
      });
      history.replace(`/pedidos/${order.id}`);
    }
    setLoading(false);
  };

  return (
    <Content>
      <Title viewName="Modificar pedido " parentName="Pedidos" />
      <OrderSummary order={order} />
      <Form form={form} onFinish={onSubmit}>
        <Table1
          rowKey={(row) => row.id}
          columns={columns}
          selection={rowSelection}
          pagination={false}
          data={currentData}
          summary={renderSummary}
        />
        <FormButton text="Enviar" loading={loading} />
      </Form>
    </Content>

  );
};

export default OrderUpdate;
