import React from 'react';
import Title from 'components/Title';
import useAuth from 'hooks/useAuth';
import { Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';

const Machinery: React.ViewComponent = ({ verboseName }) => (
  <Content>
    <Title viewName={verboseName} />
    <Typography.Title level={3} style={{ marginLeft: '1em' }}>
      <a href="https://comagrodelbajio.com/">
        <img src="https://gaqsa.com/images/image001.gif" alt="imagen" />
      </a>
    </Typography.Title>
  </Content>
);
export default Machinery;
