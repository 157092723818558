import React, { useState, useCallback, useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import {
  Button,
  notification, Popconfirm, Tooltip,
} from 'antd';
import { useHistory } from 'react-router';
import Title from 'components/Title';
import { useBackend } from 'integrations';
import {
  Laboratory,
} from '@types';
import Table from 'components/Table';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';

const ListLaboratory: React.VC = ({ verboseName, parentName }) => {
  const backend = useBackend();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  // eslint-disable-next-line max-len
  const [laboratory, setLaboratory] = useState<Laboratory[] | undefined>(undefined);

  const fetchLaboratory = useCallback(async () => {
    setLoading(true);

    const [result, error] = await backend.laboratory.getAll();

    if (error || !result) {
      notification.error({
        message: 'Ocurrió un error al cargar los laboratorios!',
        description: 'Intentalo más tarde',
      });
      setLoading(false);
      return;
    }
    setLaboratory(result.data);
    setLoading(false);
  }, [backend.laboratory]);

  useEffect(() => {
    fetchLaboratory();
  }, [history, fetchLaboratory]);

  const deleteLaboratory = async (
    id: number,
  ): Promise<void> => {
    setLoading(true);
    const [result, error] = await backend.laboratory.delete(
      `delete/${id}`,
      {
      },
    );
    if (error || !result) {
      notification.error({
        message: 'Ocurrió un error al borrar!',
        description: error?.response?.data.code === 'notallowed' ? 'Este objeto tiene otros objetos relacionados' : 'Intentalo más tarde',
      });
      setLoading(false);
      return;
    }
    setLoading(false);

    notification.success({
      message: 'Se ha eliminado el objeto exitosamente',
    });
    fetchLaboratory();
  };

  const handleButton = () : void => {
    history.replace('/laboratorios/nuevo');
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: number, cat: Laboratory) => (
        <Popconfirm
          title={`¿Está seguro de eliminar: ${cat.name}?`}
          onConfirm={() => deleteLaboratory(cat.id)}
        >
          <Button>Eliminar</Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Content>
      <Title viewName={verboseName} parentName={parentName} />
      {isLoading || !laboratory ? <LoadingIndicator /> : (
        <Table
          rowKey={(row) => `${row.id}`}
          data={
              laboratory.map((lab) => ({
                id: lab.id,
                name: lab.name,
              }))
          }
          actions={[
            {
              action: handleButton,
              text: 'Nuevo',
              icon: <PlusOutlined />,
            },
          ]}
          columns={columns}
          pagination={{ position: ['topCenter', 'bottomCenter'] }}
        />
      )}
    </Content>
  );
};

export default ListLaboratory;
