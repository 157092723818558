import { Client, OrderReportForm, Provider } from '@types';
import {
  Modal,
  Form,
  notification,
  Typography,
  InputNumber,
  DatePicker,
  Row,
  Input,
  Select,
} from 'antd';
import { useBackend } from 'integrations';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Props } from './GenerateOrderReportModal.type';

const GenerateReportModal: React.FC<Props> = ({
  visible, onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<OrderReportForm>();
  const backend = useBackend();
  const [providers, setProviders] = useState<Provider[] | undefined>(undefined);
  const [clients, setClients] = useState<Client[] | undefined>(undefined);
  const { Option } = Select;

  const handleUpdate = async (data: OrderReportForm): Promise<void> => {
    setLoading(true);

    const payload: OrderReportForm = {
      ...data,
    };

    const [result, error] = await backend.orders.post('report', {
      ...payload,
    }, {
      responseType: 'arraybuffer',
    });

    if (error || !result || !result.data) {
      notification.error({
        message: 'Error al generar el reporte de pedidos.',
      });
      setLoading(false);
      return;
    }
    console.log(result.data);

    const url = window.URL.createObjectURL(new Blob([result.data as BlobPart]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'reporte.csv');
    document.body.appendChild(link);
    link.click();

    notification.success({
      message: (
        'Reporte generado exitosamente!'
      ),
    });

    setLoading(false);
    onClose(true);
  };
  const fetchProviders = useCallback(async () => {
    setLoading(true);
    const [result, error] = await backend.providers.getAll();

    if (error || !result) {
      notification.error({
        message: 'Ocurrió un error al cargar los proveedores!',
        description: 'Intentalo más tarde',
      });
      setLoading(false);
      return;
    }
    setProviders(result.data);

    setLoading(false);
  }, [backend.providers]);

  const fetchClients = useCallback(async () => {
    setLoading(true);
    const [result, error] = await backend.clients.getAll();

    if (error || !result) {
      notification.error({
        message: 'Ocurrió un error al cargar los clientes!',
        description: 'Intentalo más tarde',
      });
      setLoading(false);
      return;
    }
    setClients(result.data);

    setLoading(false);
  }, [backend.providers]);

  useEffect(() => {
    fetchProviders();
    fetchClients();
  }, [fetchClients, backend.clients, fetchProviders, backend.providers]);

  const handleOk = async (): Promise<void> => {
    try {
      const values = await form.validateFields();
      await handleUpdate(values);
    } catch (info) {
      notification.error({
        message: 'Error al generar el reporte.',
      });
    }
  };

  const disabledDate = (
    current: any,
  ): boolean => current && current > moment().endOf('day');

  return (
    <Modal
      visible={visible}
      onCancel={() => onClose(false)}
      onOk={handleOk}
      okText="Crear"
      cancelText="Cancelar"
      title="Generar reporte de pedidos"
      confirmLoading={loading}
    >
      <Form
        form={form}
      >
        {' '}
        <Form.Item
          name="start_date"
          label="Fecha de Inicio"
          rules={[{ required: false }]}
        >
          <DatePicker
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item
          name="end_date"
          label="Fecha de Fin"
          rules={[{ required: false }]}
        >
          <DatePicker
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item
          name="provider"
          label="Proveedor"
          rules={[{ required: false }]}
        >
          <Select
            showSearch
            placeholder="Buscar proveedor"
            filterOption={
              (input, option: any) => (
                option.children.toLowerCase().indexOf(input.toLowerCase())
                >= 0
              )
            }
            allowClear
          >
            {providers && Object.values(providers).map(
              (provider) => (
                <Option value={provider.id} key={provider.id}>
                  {`${provider.name} - ${provider.nav_key}`}
                </Option>
              ),
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name="client"
          label="Cliente"
          rules={[{ required: false }]}
        >
          <Select
            showSearch
            placeholder="Buscar cliente"
            filterOption={
              (input, option: any) => (
                option.children.toLowerCase().indexOf(input.toLowerCase())
                >= 0
              )
            }
            allowClear
          >
            {clients && Object.values(clients).map(
              (provider) => (
                <Option value={provider.id} key={provider.id}>
                  {`${provider.name} - ${provider.internal_key}`}
                </Option>
              ),
            )}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GenerateReportModal;
