/* eslint-disable react/jsx-props-no-spreading */
import {
  Col,
  Form, Input, InputNumber, Row, Tooltip, Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import TableFilter from 'components/TableFilter';
import { Product, ProductGroup, ProductProvider } from '@types';
import DiscountText from 'components/DiscountText';
import Table from 'components/Table';
import FormButton from 'components/FormButton';
import LoadingIndicator from 'components/LoadingIndicator';
import Props from './PriceChangeForm.type';

const PriceChangeForm: React.FC<Props> = ({
  form,
  onFinish,
  onFinishFailed,
  isLoading,
  products,
}) => {
  const [filtered, setFiltered] = useState<Product[]>([]);
  const resetFiltered = useCallback(
    () => setFiltered(products || []), [products],
  );
  const [code, setCode] = useState<string>('');

  const renderLabs = (
    product: ProductGroup,
  ): any => {
    if (!product.laboratory) {
      return 'No disponible';
    }

    return (
      <Row style={{ textAlign: 'left' }}>
        {product.laboratory.name}
      </Row>
    );
  };

  const putDecimals = (value: any): any => {
    if (value === null || Number.isNaN(parseFloat(value))) {
      return undefined;
    }

    return parseFloat((value as number).toString()).toFixed(2);
  };

  const formatPrice = (value: any): any => {
    let stringVal = `${value}`;
    stringVal.replace(
      /\B(?=(\d{3})+(?!\d))/g, ',',
    );
    stringVal = putDecimals(stringVal);
    return stringVal;
  };
  const columns = [
    {
      title: 'Clave',
      dataIndex: 'key',
      key: 'key',
      sorter: (a: any, b: any) => a.key.localeCompare(b.key),
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Presentación',
      dataIndex: 'presentation',
      key: 'presentation',
    },
    {
      title: 'Substancia activa',
      dataIndex: 'active_substance',
      key: 'active_substance',
    },
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Laboratorio',
      dataIndex: 'laboratory',
      key: 'laboratory',
      render: (
        _: number, product: ProductGroup,
      ) => renderLabs(product),
      sorter: (a: any, b: any) => a.laboratory.localeCompare(b.laboratory),
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (_: number, product: Product) => (
        <Tooltip title={product.provider.offer ? product.provider.offer.motive : null}>
          <Row>
            <DiscountText
              originalPrice={product.provider.price}
              discount={product.provider.offer ? (product.provider.price - product.provider.offer?.discount_price) / product.provider.price : 0}
            />
          </Row>
        </Tooltip>

      ),
    },
    {
      title: 'Nuevo Precio',
      dataIndex: 'new_price',
      key: 'new_price',
      render: (fieldKey: number, product: Product) => (
        <>
          <Form.Item
            name={['products', product.id, 'new_price']}
            initialValue={product.provider.temp_price}
            style={{ margin: 0 }}
          >
            <InputNumber
              size="small"
              defaultValue={product.provider.temp_price}
              min={0.01}
              prefix="$"
              precision={2}
              step={0.01}
              style={{ paddingLeft: 10 }}
            />
          </Form.Item>
          <Form.Item
            name={['products', product.id, 'product']}
            initialValue={product.id}
            hidden
            style={{ display: 'none' }}
          />
        </>
      ),
    },
  ];

  const onFilterAny = (
    data: Product[], value: string,
  ): Product[] => data.filter((product) => (
    product.name.toLowerCase().includes(
      value.toLowerCase(),
    )
    || product.presentation.toLowerCase().includes(
      value.toLowerCase(),
    )
    || product.active_substance.toLowerCase().includes(
      value.toLowerCase(),
    )
    || (
      typeof product.category === 'string'
      && product.category.toLowerCase().includes(
        value.toLowerCase(),
      )
    )
    || (
      typeof product.laboratory === 'string'
      && product.laboratory.toLowerCase().includes(
        value.toLowerCase(),
      )
    )
  ));

  useEffect(() => {
    resetFiltered();
  }, [products, resetFiltered]);

  return (
    <Form
      form={form}
      name="PriceChangeForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row justify="center">
        <Col span={6}>
          <Typography>
            Código para Cambio de Precio
          </Typography>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={6}>
          <Form.Item
            name="token"
          >
            <Input
              size="small"
              value={code}
              onChange={(event) => setCode(event.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      {products ? (
        <>
          <TableFilter
            useAny
            fieldsToFilter={[
              { key: 'key', value: 'Clave' },
              { key: 'name', value: 'Nombre' },
              { key: 'presentation', value: 'Presentación' },
              { key: 'active_substance', value: 'Substancia activa' },
              { key: 'category', value: 'Categoria' },
              { key: 'status', value: 'Status' },
            ]}
            customFieldsToFilter={[
              { key: 'laboratory.name', value: 'Laboratorio' },
              { key: 'providers.provider.name', value: 'Proveedor' },
            ]}
            onFilter={setFiltered}
            filterAny={onFilterAny}
            data={products}
          />
          <Table
            rowKey={(row) => `${row.id}`}
            data={filtered}
            columns={columns}
            pagination={false}
          />
        </>
      ) : <LoadingIndicator />}
      <FormButton
        loading={isLoading}
        text="Confirmar"
      />
    </Form>
  );
};

export default PriceChangeForm;
