import React, { useState, useCallback, useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Tooltip,
} from 'antd';
import { useHistory } from 'react-router';
import Title from 'components/Title';
import { useBackend } from 'integrations';
import {
  Order, Client, Provider,
} from '@types';
import Table, { Column } from 'components/Table';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import moment from 'moment';

import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import OrderStatusTag from 'components/OrderStatusTag';
import { CloseOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import useAuth from 'hooks/useAuth';
import { OrderStatus } from 'constants/strings';
import TableFilter from 'components/TableFilter';
import OrderInvoiceStatusTag from 'components/OrderInvoiceStatusTag';
import NumberFormat from 'react-number-format';
import { MICROSERVICE_ENABLED, MICROSERVICE_ROUTE } from 'settings';
import GenerateReportModal from 'components/Modals/GenerateOrderReportModal/GenerateOrderReportModal';

interface GenerateReportModal {
  visible: boolean,
}

const ListOrders: React.VC = ({ verboseName, parentName }) => {
  const backend = useBackend();
  const history = useHistory();
  const auth = useAuth();

  const [isLoading, setLoading] = useState(true);
  const [orders, setOrders] = useState<Order[] | undefined>(undefined);
  const {
    isClient, isProvider, isAdmin, isLaboratory,
  } = useAuth();

  const shouldShowModifyOrder = isProvider;

  const shouldShowCancelOrder = isClient || isProvider;

  const [filtered, setFiltered] = useState<Order[]>([]);

  const [generateReportModal, setGenerateReportModal] = useState<GenerateReportModal>(
    { visible: false },
  );

  const resetFiltered = useCallback(
    () => setFiltered(orders || []), [orders],
  );

  const onCloseModal = (success: boolean): void => {
    setGenerateReportModal({ ...generateReportModal, visible: false });
  };

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    const newMethodSwitch = false;

    if (MICROSERVICE_ENABLED) {
      let temp: Order[] = [];
      await fetch(MICROSERVICE_ROUTE, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, // this line is important, if this content-type is not set it wont work
        body: `c=o&a=${auth.user?.id}&b=${auth.user?.groups[0]}`,
      }).then((response) => response.json()).then((response) => {
        temp = response;
      });
      setOrders(temp);
    } else {
      const [result, error] = await backend.orders.getAll();
      if (error || !result) {
        notification.error({
          message: 'Ocurrió un error al cargar los pedidos!',
          description: 'Intentalo más tarde',
        });
        setLoading(false);
        return;
      }
      setOrders(result.data);
    }
    setLoading(false);
  }, [backend.orders]);

  useEffect(() => {
    fetchOrders();
  }, [history, fetchOrders]);

  const onCancelOrder = async (
    id: number,
  ): Promise<void> => {
    setLoading(true);
    const cancelled = true;
    const comment = prompt('Por favor, dinos el motivo de tu cancelacion:');
    const [result, error] = await backend.orders.patch(
      `/orders/${id}/cancelled`,
      {
        cancelled,
        comment,
      },
    );
    if (error || !result) {
      let description;

      switch (error?.response?.data.code) {
        case 'NOT_ALLOWED':
          description = 'No se permite cancelar el pedido, ya existe una factura en el sistema';
          break;
        default:
          description = 'Intentelo de nuevo';
          break;
      }
      notification.error({
        message: 'Ocurrió un error al cambiar el estado del producto!',
        description,
      });
      setLoading(false);
      return;
    }
    setLoading(false);

    notification.success({
      message: 'Se ha cancelado la orden exitosamente',
    });
    fetchOrders();
  };

  const renderTotal = (
    order: Order,
  ): any => (
    <Row>
      <NumberFormat
        thousandSeparator=","
        thousandsGroupStyle="thousand"
        displayType="text"
        value={order.total}
        prefix="$"
        decimalScale={2}
        fixedDecimalScale
      />
    </Row>
  );

  const renderDate = (
    order: Order,
  ): any => (
    <Row>
      {moment(order.created_at).format('YYYY-MM-DD')}
    </Row>
  );

  useEffect(() => {
    resetFiltered();
  }, [orders, resetFiltered]);

  const onFilterAny = (
    data: Order[], value: string,
  ): Order[] => data.filter((order) => (
    (
      typeof order.user === 'string'
      && order.user.toLowerCase().includes(
        value.toLowerCase(),
      )
    )
    || (
      typeof order.provider === 'string'
      && order.provider.toLowerCase().includes(
        value.toLowerCase(),
      )
    )
    || (
      order.status.toLowerCase().includes(
        value.toLocaleLowerCase(),
      )
    )
  ));

  const columns = [
    {
      title: 'Orden',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: Order, b: Order) => {
        if (moment(a.created_at).isBefore(moment(b.created_at))) {
          return -1;
        }
        return 1;
      },
      render: (
        _: number, order: Order,
      ) => renderDate(order),
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      sorter: (a: any, b: any) => a.client.name.localeCompare(b.client.name),
      render: (_: number, order: Order) => (
        `${((order as Order).client as Client)?.internal_key} - ${((order as Order).client as Client)?.name}`
      ),
    },
    {
      title: 'Proveedor',
      dataIndex: 'provider',
      key: 'provider',
      sorter: (a: any, b: any) => a.provider.name.localeCompare(b.provider.name),
      render: (_: number, order: Order) => (
        `${((order as Order).provider as Provider)?.internal_key} - ${((order as Order).provider as Provider)?.name}`
      ),
    },
    {
      title: 'Estado del pedido',
      dataIndex: 'status',
      key: 'status,',
      render: (status: string) => (
        <OrderStatusTag status={status} />
      ),
    },
    {
      title: 'Estado de facturación',
      dataIndex: 'invoice_status',
      key: 'invoice_status',
      render: (status: string) => (
        <OrderInvoiceStatusTag status={status} />
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total,',
      sorter: (a: any, b: any) => a.total - b.total,
      render: (
        _: number, order: Order,
      ) => renderTotal(order),
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: number, order: Order) => (
        <Row style={{ display: 'flex' }}>
          { !isLaboratory ? (
            <Col>
              <Tooltip title="Ver detalles">
                <Button
                  shape="circle"
                  icon={<SearchOutlined />}
                  onClick={() => {
                    history.push(`/pedidos/${order.id}`);
                  }}
                />
              </Tooltip>
            </Col>
          )
            : null}

          {shouldShowModifyOrder
            ? (
              <Col>
                <Tooltip title={(order.status !== OrderStatus.PENDING)
                  ? 'Este pedido ya fue modificado' : 'Modificar pedido'}
                >
                  <Button
                    shape="circle"
                    disabled={order.status !== OrderStatus.PENDING}
                    icon={<EditOutlined />}
                    onClick={() => {
                      history.push(`/pedidos/${order.id}/modificar`);
                    }}
                  />
                </Tooltip>
              </Col>
            )
            : null}
          {shouldShowCancelOrder && order.status === 'Pendiente'
            && !order.cancelled
            ? (
              <Col>
                <Tooltip title="Cancelar orden">
                  <Popconfirm
                    title="¿Estás seguro de cancelar esta orden?"
                    onConfirm={() => onCancelOrder(
                      order.id,
                    )}
                  >
                    <Button
                      shape="circle"
                      icon={<CloseOutlined />}
                    />
                  </Popconfirm>
                </Tooltip>
              </Col>
            )
            : null}
        </Row>
      ),
    },
  ];

  const getColumns = (): Column[] => {
    if (isAdmin) {
      return columns;
    }
    const providerCol = ['Proveedor', 'Clave del Proveedor'];
    const clientCol = ['Cliente', 'Clave del Cliente'];

    return columns.filter((col) => (isClient ? !clientCol.includes(col.title) : !providerCol.includes(col.title)));
  };

  return (
    <Content>
      <Title viewName={verboseName} parentName={parentName} />
      {(isLoading || !orders) ? <LoadingIndicator />
        : (
          <>
            <TableFilter
              fieldsToFilter={[
                { key: 'id', value: 'Orden' },
                { key: 'status', value: 'Estado del pedido' },
                { key: 'invoice_status', value: 'Estado de la facturacion' },
                { key: 'created_at', value: 'Fecha de Pedido' },
              ]}
              customFieldsToFilter={[
                { key: 'client.name', value: 'Cliente' },
                { key: 'provider.name', value: 'Proveedor' },
              ]}
              onFilter={setFiltered}
              filterAny={onFilterAny}
              data={orders}
            />
            {isAdmin || isLaboratory ? (
              <>
                <Row
                  gutter={16}
                  justify="end"
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                >
                  <Col className="gutter-row" span={6}>
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      block
                      onClick={() => (
                        setGenerateReportModal({
                          visible: true,
                        }))}
                    >
                      Descargar Reporte
                    </Button>
                  </Col>
                </Row>
                <GenerateReportModal
                  visible={generateReportModal.visible}
                  onClose={onCloseModal}
                />
              </>
            ) : null}
            <Table
              rowKey={(row) => row.id}
              data={filtered}
              columns={getColumns()}
              pagination={{ position: ['topCenter', 'bottomCenter'] }}
            />
          </>
        )}
    </Content>
  );
};

export default ListOrders;
