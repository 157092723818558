import React, { useState, useCallback, useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import {
  Button,
  Menu,
  notification,
  Popconfirm,
  Tooltip,
} from 'antd';
import { useHistory } from 'react-router';
import Title from 'components/Title';
import { useBackend } from 'integrations';
import {
  Category,
} from '@types';
import Table from 'components/Table';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';

const ListCategory: React.VC = ({ verboseName, parentName }) => {
  const backend = useBackend();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  // eslint-disable-next-line max-len
  const [category, setCategory] = useState<Category[] | undefined>(undefined);

  const handleButton = (): void => {
    history.replace('/categorias/nuevo');
  };

  const fetchCategory = useCallback(async () => {
    setLoading(true);

    const [result, error] = await backend.category.getAll();

    if (error || !result) {
      notification.error({
        message: 'Ocurrió un error al cargar las categorías!',
        description: 'Intentalo más tarde',
      });
      setLoading(false);
      return;
    }
    setCategory(result.data);
    setLoading(false);
  }, [backend.category]);

  useEffect(() => {
    fetchCategory();
  }, [history, fetchCategory]);

  const deleteCategory = async (
    id: number,
  ): Promise<void> => {
    setLoading(true);
    const [result, error] = await backend.category.delete(
      `delete/${id}`,
      {
      },
    );
    if (error || !result) {
      notification.error({
        message: 'Ocurrió un error al borrar!',
        description: error?.response?.data.code === 'notallowed' ? 'Este objeto tiene otros objetos relacionados' : 'Intentalo más tarde',
      });
      setLoading(false);
      return;
    }
    setLoading(false);

    notification.success({
      message: 'Se ha eliminado el objeto exitosamente',
    });
    fetchCategory();
  };

  const columns = [
    {
      title: 'Código',
      dataIndex: 'key',
      key: 'key',
      sorter: (a: any, b: any) => a.key.localeCompare(b.key),
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: number, cat: Category) => (
        <Popconfirm
          title={`¿Está seguro de eliminar: ${cat.name}?`}
          onConfirm={() => deleteCategory(cat.id)}
        >
          <Button>Eliminar</Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Content>
      <Title viewName={verboseName} parentName={parentName} />
      {isLoading || !category ? <LoadingIndicator /> : (
        <>
          <Table
            rowKey={(row) => `${row.id}`}
            data={category.map((cat) => ({
              id: cat.id,
              key: cat.code.toUpperCase(),
              name: cat.name,
            }))}
            actions={[
              {
                action: handleButton,
                text: 'Nuevo',
                icon: <PlusOutlined />,
              },
            ]}
            columns={columns}
            pagination={{ position: ['topCenter', 'bottomCenter'] }}
          />

        </>
      )}
    </Content>
  );
};

export default ListCategory;
